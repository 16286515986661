// @flow

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import './CopyButton.css';

import type { Component } from '../types/Component';

type Props = {
  className: string,
  text: string
};

const CLASS_HIDDEN = 'hidden';
const CLASS_VISIBLE = 'visible';
const DELAY_MS = 200;

const CopyButton: Component = (props: Props) => {
  const [className, setClassName] = useState(CLASS_HIDDEN);

  const { t } = useTranslation();

  /**
   * Copies the text to the clipboard and toggles the copied alert.
   *
   * @type {(function(): void)|*}
   */
  const onClick = useCallback(() => {
    // Copy the text to the clipboard
    navigator.clipboard.writeText(props.text);

    // Set the badge to be visible
    setClassName(CLASS_VISIBLE);

    // Delay hiding the badge
    _.delay(() => setClassName(CLASS_HIDDEN), DELAY_MS);
  }, [props.text]);

  return (
    <button
      className={`copy-button ${props.className}`}
      onClick={onClick}
      type='button'
    >
      <i
        className='fe fe-lg fe-copy'
      />
      <h3
        className={`badge-container ${className}`}
      >
          <span
            className='badge bg-primary-desat p-2'
          >
            { t('CopyButton.labels.copied') }
          </span>
      </h3>
    </button>
  );
};

export default CopyButton;
