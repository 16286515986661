// @flow

import BaseService from '../BaseService';

/**
 * Service class for handling subscription requests.
 */
class Subscriptions extends BaseService {
  /**
   * Returns the base URL for the subscriptions API.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/stripe/subscriptions';
  }
}

const SubscriptionsService: Subscriptions = new Subscriptions();
export default SubscriptionsService;
