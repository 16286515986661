// @flow

import axios from 'axios';
import FilterService from './FilterService';
import UserLicense from '../transforms/UserLicense';

/**
 * Class for handling all user license API requests.
 */
class UserLicenses extends FilterService {
  /**
   * Returns the user licenses base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/user_licenses';
  }

  /**
   * Returns the user licenses transform.
   *
   * @returns {UserLicense}
   */
  getTransform(): * {
    return UserLicense;
  }

  /**
   * Calls the api/user_licenses/reminders API end point.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendRenewalReminders(): Promise<any> {
    return axios.post(`${this.getBaseUrl()}/renewals`, {}, this.getConfig());
  }

  /**
   * Calls the api/user_licenses/subscriptoins API end point.
   *
   * @returns {Promise<AxiosResponse<any>>}
   */
  sendSubscriptionReminders(): Promise<any> {
    return axios.post(`${this.getBaseUrl()}/subscriptions`, {}, this.getConfig());
  }
}

const UserLicensesService: UserLicenses = new UserLicenses();
export default UserLicensesService;
