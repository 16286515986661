// @flow

import React from 'react';
import PriceCards from '../components/PriceCards';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';
import { ReactComponent as Blur3 } from '../shapes/blurs/blur-3.svg';

import type { Component } from '../types/Component';

const Prices: Component = () => (
  <>
    <section
      className='pt-9 pt-md-12 pb-12 bg-gray-200'
    >
      <div
        className='shape shape-blur-3 text-dark'
      >
        <Blur3 />
      </div>
      <PriceCards />
    </section>
    <div
      className='position-relative mt-n15'
    >
      <div
        className='shape shape-bottom shape-fluid-x text-dark'
      >
        <Curve1 />
      </div>
    </div>
    <section
      className='pt-15 pb-8 bg-dark'
    />
  </>
);

export default Prices;
