// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import PricesService from '../services/stripe/Prices';

import type { Component } from '../types/Component';

type Props = {
  error?: boolean,
  fluid?: boolean,
  onChange: (e: Event, data: any) => void,
  required?: boolean,
  value: any
};

const PriceInput: Component = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (props.value) {
      setLoading(true);

      PricesService
        .fetchOne(props.value)
        .then(() => setValid(true))
        .catch(() => setValid(false))
        .finally(() => setLoading(false));
    }
  }, [props.value]);

  /**
   * Sets the icon to display on the input element.
   *
   * @type {unknown}
   */
  const icon = useMemo(() => {
    if (loading) {
      return null;
    }

    return (
      <Icon
        color={valid ? 'green' : 'red'}
        name={valid ? 'checkmark' : 'exclamation circle'}
      />
    );
  }, [loading, valid]);

  return (
    <Form.Input
      error={props.error}
      fluid={props.fluid}
      icon={icon}
      loading={loading}
      onChange={props.onChange}
      required={props.required}
      value={props.value}
    />
  );
};

export default PriceInput;
