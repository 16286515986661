// @flow

type Function = (timestamp: string | number) => string;

const TimeFormat = {
  hour: '2-digit',
  minute: '2-digit'
};

const FormatType = {
  date: 'date',
  dateTime: 'dateTime',
  time: 'time'
};

/**
 * Returns the passed date as a formatted string based on the passed type.
 *
 * @param date
 * @param type
 *
 * @returns {null|*}
 */
const getDateTimeFromDate: Function = (date: Date, type: string) => {
  if (!date) {
    return null;
  }

  return format(date, type);
};

/**
 * Returns the passed timestamp string as a formatted date string based on the passed type.
 *
 * @param timestamp
 * @param type
 *
 * @returns {null|*}
 */
const getDateTimeFromString: Function = (timestamp: string, type: string) => {
  if (!timestamp) {
    return null;
  }

  return format(new Date(timestamp), type);
};

/**
 * Returns the passed timestamp number as a formatted date string based on the passed type.
 *
 * @param timestamp
 * @param type
 *
 * @returns {null|*}
 */
const getDateTimeFromNumber: Function = (timestamp: number, type: string) => {
  if (!timestamp) {
    return null;
  }

  return format(new Date(timestamp * 1000), type);
};

// private

const format = (date, type) => {
  let value;

  if (type === FormatType.date) {
    value = formatDate(date);
  } else if (type === FormatType.time) {
    value = formatTime(date);
  } else if (type === FormatType.dateTime) {
    value = formatDateTime(date);
  }

  return value;
};

const formatDate = (date) => date.toLocaleDateString();
const formatDateTime = (date) => `${formatDate(date)} ${formatTime(date)}`;
const formatTime = (date) => date.toLocaleTimeString([], TimeFormat);

export default {
  getDateTimeFromDate,
  getDateTimeFromNumber,
  getDateTimeFromString
};

export {
  FormatType
};
