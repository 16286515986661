// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import LicenseGroups from './LicenseGroups';
import UserLicenses from './UserLicenses';
import './Licenses.css';

import type { Component } from '../../types/Component';
import type { Translateable } from '../../types/Translateable';

const TAB_KEY = 'licenses.tab';

const Tabs = {
  groupLicenses: 'groupLicenses',
  userLicenses: 'userLicenses'
};

const Licenses = (props: Translateable) => {
  const [tab, setTab] = useState(Tabs.userLicenses);

  /**
   * Sets the new tab value and stores the value in session storage.
   *
   * @type {(function(*=): void)|*}
   */
  const onTabChange = useCallback((t) => {
    setTab(t);
    sessionStorage.setItem(TAB_KEY, t);
  }, []);

  /**
   * Loads the tab value from session storage.
   */
  useEffect(() => {
    const lastTab = sessionStorage.getItem(TAB_KEY);

    if (lastTab) {
      setTab(lastTab);
    }
  }, []);

  return (
    <div
      className='licenses'
    >
      <div
        className='button-container'
      >
        <Button.Group
          basic
        >
          <Button
            active={tab === Tabs.userLicenses}
            content={props.t('Licenses.buttons.user')}
            icon='user'
            onClick={() => onTabChange(Tabs.userLicenses)}
          />
          <Button
            active={tab === Tabs.groupLicenses}
            content={props.t('Licenses.buttons.group')}
            icon='users'
            onClick={() => onTabChange(Tabs.groupLicenses)}
          />
        </Button.Group>
      </div>
      { tab === Tabs.userLicenses && (
        <UserLicenses />
      )}
      { tab === Tabs.groupLicenses && (
        <LicenseGroups />
      )}
    </div>
  );
};

const LicensesPage: Component = withTranslation()(Licenses);
export default LicensesPage;
