// @flow

import { useEffect } from 'react';

import type { Component } from '../types/Component';

type Props = {
  onLoad?: () => void,
  src: string
};

const Script: Component = (props: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = props.src;
    script.async = true;

    if (props.onLoad) {
      script.onload = props.onLoad;
    }

    if (document.body) {
      document.body.appendChild(script);
    }

    return () => {
      if (document.body) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return null;
};

export default Script;
