// @flow

import type { LicenseGroup } from '../types/LicenseGroup';

type GetManageUrlType = (licenseGroup: LicenseGroup) => string;

/**
 * Returns the license manage URL for the passed license group.
 *
 * @param licenseGroup
 *
 * @returns {`/manage/${string}`}
 */
const getManageUrl: GetManageUrlType = (licenseGroup: LicenseGroup) => `/manage/${licenseGroup.secure_id}`;

type GetPaymentUrlType = (licenseGroup: LicenseGroup, reload?: boolean) => string;

/**
 * Returns the checkout success URL for the passed license group.
 *
 * @param licenseGroup
 * @param reload
 *
 * @returns {`/checkout_success/${string}?secure_id=${string}&reload=${string}`}
 */
const getPaymentUrl: GetPaymentUrlType = (licenseGroup: LicenseGroup, reload: boolean = false) => (
  `/checkout_success/${licenseGroup.payment_intent_id}?secure_id=${licenseGroup.secure_id}&reload=${reload.toString()}`
);

export default {
  getManageUrl,
  getPaymentUrl
};
