// @flow

import React from 'react';
import { useEditContainer } from 'react-components';
import Input from '../components/Input';
import Authentication from '../services/Authentication';
import Session from '../services/Session';
import { useNavigate } from 'react-router-dom';

import type { Component } from '../types/Component';

const LoginForm = useEditContainer((props) => (
  <section
    className='section-border border-primary'
  >
    <div
      className='container d-flex flex-column'
    >
      <div
        className='row align-items-center justify-content-center gx-0 min-vh-100'
      >
        <div
          className='col-12 col-md-5 col-lg-4 py-8 py-md-11'
        >
          <h1
            className='mb-6 fw-bold text-center'
          >
            Administrator Login
          </h1>
          <form
            className='mb-6'
            onSubmit={(e) => {
              e.preventDefault();
              e.stopPropagation();

              return props.onSave();
            }}
          >
            <div
              className='form-group'
            >
              <label
                className='form-label'
                htmlFor='email'
              >
                Email Address
              </label>
              <Input
                autoFocus
                className='form-control'
                error={props.isError('email')}
                name='email'
                onChange={props.onTextInputChange}
                placeholder='name@address.com'
                required
                type='email'
                value={props.item.email}
              />
              <div
                className='invalid-feedback'
              >
                Please enter a valid email address.
              </div>
            </div>
            <div
              className='form-group'
            >
              <label
                className='form-label'
                htmlFor='password'
              >
                Password
              </label>
              <Input
                className='form-control'
                error={props.isError('password')}
                name='password'
                onChange={props.onTextInputChange}
                placeholder='Password'
                required
                type='password'
                value={props.item.password}
              />
              <div
                className='invalid-feedback'
              >
                Password incorrect.
              </div>
            </div>
            <button
              className='btn w-100 btn-primary'
              type='submit'
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
));

const Login: Component = () => {
  const navigate = useNavigate();

  return (
    <LoginForm
      onSave={(item) => (
        Authentication
          .login(item.email, item.password)
          .then((response) => {
            Session.create(response);
            navigate('/admin', { state: { reload: true } });
          })
          .catch(() => {
            Session.destroy();
          })
      )}
    />
  );
};

export default Login;
