// @flow

import React from 'react';
import type { Component } from '../types/Component';
import './Eula.css';

/* eslint max-len: 0 */
/* eslint react/jsx-one-expression-per-line: 0 */

const Eula: Component = () => (
  <div
    className='eula'
  >
    <p className='c7'><span className='c1'>By downloading and using the FairCopy App, You agree, without reservation to be bound by the terms of this EULA. If You do not agree with the terms of this EULA, please do not download and use the FairCopy App.</span>
    </p>
    <p className='c7'><span className='c1'>If You accept the terms and conditions in this EULA on behalf of a company or other legal entity, You warrant that You have full legal authority to accept the terms and conditions in this EULA on behalf of such company or other legal entity, and to legally bind such company or other legal entity.</span>
    </p>
    <p className='c7'><span className='c1'>You may not accept this EULA if You are not of legal age to form a binding contract with Performant Software Solutions LLC. (&ldquo;PSS&rdquo;)</span>
    </p>
    <h2 className='c3' id='h.lgy90ibyij1a'><span className='c4'>Definitions</span></h2>
    <p className='c7'><span className='c1'>In this EULA the expressions below shall have the meaning assigned to them in this clause, unless the context requires otherwise:</span>
    </p>
    <ul className='c6 lst-kix_857uytfch41n-0 start'>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Activate&rdquo;</span>
        <span className='c1'>&nbsp;enabling the Full version of the application with the License Key provided by PSS;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Documentation&rdquo;</span>
        <span className='c1'>&nbsp;the detailed information about the FairCopy App, its features and the system requirements as made available on the website of PSS, as amended from time to time;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;FairCopy App&rdquo;</span>
        <span className='c1'>&nbsp;any software application and/or all of the contents of the files and/or other media, including software setup files, licensed to You by PSS, including any Updates;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Full version&rdquo;</span>
        <span className='c1'>&nbsp;the license for the FairCopy App;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;License Key&rdquo;</span><span className='c1'>&nbsp;a unique code provided by PSS, which enables You to activate the Trial version or Full version by entering the code into the FairCopy App and to subsequently use the FairCopy App during the applicable license term;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Open Source Software&rdquo;</span>
        <span className='c1'>&nbsp;any software that requires as a condition of use, copying, modification and/or distribution of such software that such software or other software incorporated into, derived from or distributed with such software (a) be disclosed or distributed in source code form, and (b) be licensed for the purpose of making and/or distributing derivative works, and (c) be redistributable at no charge;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Updates&rdquo;</span><span className='c1'>&nbsp;any modified versions and updates of, and additions to the FairCopy App (excluding upgrades of the FairCopy App);</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;Use&rdquo;</span><span className='c1'>&nbsp;the access, download, install, copy or get benefit from using the FairCopy App in accordance with the documentation;</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c2'>&ldquo;You&rdquo;</span><span className='c1'>&nbsp;you, the final and ultimate user of the FairCopy App or the authorized representative of a company or other legal entity that will be the final and ultimate user of the FairCopy App, and the company or other legal entity that will be the final and ultimate user of the FairCopy App, if applicable.</span>
      </li>
    </ul>
    <h2 className='c3' id='h.pu73maofybg6'><span className='c4'>General</span></h2>
    <ol className='c6 lst-kix_dzu3kd4k57e-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>This EULA applies to any licenses granted to You by PSS for the use of the FairCopy App.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>By obtaining a License Key for the FairCopy App and/or downloading and using the FairCopy App, You enter into this EULA with PSS.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>This EULA may be modified from time to time. PSS will notify you of such modifications on its website or otherwise, e.g. by using the email address used for the provision of the License Key. Any modifications to this EULA shall only apply upon acceptance by You.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.14b6vpd4rp1d'><span className='c4'>License</span></h2>
    <ol className='c6 lst-kix_b4qve45wen7k-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>PSS grants You a non-exclusive, non-transferable, limited, revocable license to Use the FairCopy App in accordance with this EULA. PSS reserves all rights not expressly granted to You.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>PSS is and remains the owner of any intellectual property rights with respect to the FairCopy App. You shall not acquire any ownership to the FairCopy App as a result of Your obtaining a License Key or Your Use of the FairCopy App.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.qvl204sp6ezs'><span className='c4'>Permitted use and restrictions</span></h2>
    <ol className='c6 lst-kix_jsgwm37ii3bz-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>In order to be able to install the FairCopy App and receive Updates and upgrades, Your computer shall have access to the Internet and shall meet the system requirements described in the Documentation.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>You may Use the FairCopy App on one computer only. If You exceed the limit, PSS may block the License Key.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>You shall Use the FairCopy App in accordance with applicable laws and shall not:</span>
      </li>
    </ol>
    <ol className='c6 lst-kix_jsgwm37ii3bz-1 start' start='1'>
      <li className='c5 li-bullet-0'><span className='c1'>rent, lease, lend, sell, redistribute, sublicense or otherwise commercially exploit the License Key;</span>
      </li>
      <li className='c5 li-bullet-0'><span className='c1'>Use the FairCopy App for any unlawful or illegal activity, or to facilitate an unlawful or illegal activity;</span>
      </li>
      <li className='c5 li-bullet-0'><span className='c1'>delete or alter any disclaimers, warnings, copyright or other proprietary notices accompanying the FairCopy App; or</span>
      </li>
      <li className='c5 li-bullet-0'><span className='c1'>copy (except as otherwise provided in this EULA), adapt, translate, decompile, reverse engineer, disassemble, attempt to derive the source code of, modify or create derivative works of the FairCopy App and the License Key or any parts thereof, except to the extent permitted by applicable law.</span>
      </li>
    </ol>
    <ol className='c6 lst-kix_jsgwm37ii3bz-0' start='4'>
      <li className='c0 li-bullet-0'><span className='c1'>PSS may modify the FairCopy App at any time at its sole discretion and without notice to You, for example to comply with applicable law or a court order, to avoid a third party infringement claim or to provide Updates and upgrades.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.6zlfkk5lipge'><span className='c4'>Maintenance and support</span></h2>
    <ol className='c6 lst-kix_ognwulezuyhv-0 start' start='1'>
      <li className='c0 li-bullet-0'>
        <span className='c1'>PSS will notify You of any available Updates and upgrades.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>You will be entitled to receive Updates free of charge during the applicable license term.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>Any maintenance and support provided by PSS, including the provision of Updates and upgrades, will be provided in an adequate way on an &ldquo;as is&rdquo; basis without any warranty, as soon as reasonably practicable, subject to availability of personnel.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.mulpann94rb7'><span className='c4'>Use of data</span></h2>
    <ol className='c6 lst-kix_cd5vg2xl7azd-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>PSS may periodically collect and use technical and related data concerning the FairCopy App You have licensed, including about the version number of the FairCopy App You have installed and about the system You have installed FairCopy App on. FairCopy will use such data to facilitate maintenance and support with respect to the FairCopy App, to improve its products and to provide further services or technologies to You.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>PSS may process personal data with respect to You, if and to the extent necessary to provide the License Key, to provide maintenance and support to You with respect to the FairCopy App and to comply with its obligations under this EULA. To the extent PSS will process personal data, it will comply with its obligations under applicable data protection law.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.j2g8sg56frtl'><span className='c4'>Disclaimer</span></h2>
    <ol className='c6 lst-kix_rkgd32a0ycev-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>You Use the FairCopy App at Your own risk and the entire risk as to satisfactory quality, performance and accuracy is with You.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>The FairCopy App and accompanying documentation are provided on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis without warranty - express or implied - of any kind, and PSS specifically disclaims the warranty of fitness for a particular purpose. No oral or written advice given by PSS, its dealers, distributors, agents or employees shall create a warranty or in any way increase the scope of this warranty and You may not rely upon such information or advice.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.tgejeqlhpmc9'><span className='c4'>Liability limitation</span></h2>
    <ol className='c6 lst-kix_yugucnmug9v9-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>The liability of PSS and any third party that has been involved in the creation, production, or delivery of the FairCopy App for all damages arising out of or in any way relating to the Full version, the License Key, the FairCopy App and/or this EULA shall in no event exceed the purchase price.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>PSS and any third party that has been involved in the creation, production, or delivery of the FairCopy App are under no circumstances liable for consequential or indirect damages (including damage for loss of profit, business interruption, loss of data, and the like arising out of the use or inability to use the FairCopy App).</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>The limitations of liability referred to in clause 9.1 and 9.2 shall not apply in the event that: (i) any negligence on the part of PSS leads to death or physical injury or (ii) damages arise from the intent, willful misconduct or gross negligence of PSS or the management of PSS.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.hpeeu2gq6ft'><span className='c4'>Termination</span></h2>
    <ol className='c6 lst-kix_2rs1hvsablfb-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>This EULA will continue to be in force until the expiry or termination of the Full version.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>The Full version will terminate automatically at the end of the applicable license term, unless renewed or Activated prior to the end of the applicable license term.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>PSS is entitled to terminate the Full version by blocking the License Key during the license term with immediate effect and without prior notice in the event You fail to comply with the terms in this EULA, or in the event PSS is required to do so by law or an order of an applicable court.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>Upon termination of the Full version, You shall cease all use of the FairCopy App.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>You acknowledge that the provisions of this EULA, which by their nature are intended to survive termination, will remain in effect after termination of this EULA.</span>
      </li>
    </ol>
    <h2 className='c3' id='h.fncyg7mknycn'><span className='c4'>Governing law and disputes</span></h2>
    <ol className='c6 lst-kix_6asaomqugjf-0 start' start='1'>
      <li className='c0 li-bullet-0'><span className='c1'>This EULA shall be governed by and construed in accordance with the laws of the Commonwealth of Virginia in the United States, excluding its conflicts of law rules.</span>
      </li>
      <li className='c0 li-bullet-0'><span className='c1'>Any dispute between PSS and You shall exclusively be submitted to the competent court in the Commonwealth of Virginia.</span>
      </li>
    </ol>
    <p className='c10'>
      <span className='c11' />
    </p>
  </div>
);

export default Eula;
