// @flow

import React from 'react';
import { Message } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import DateUtils, { FormatType } from '../utils/Date';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';
import type { UserLicense } from '../types/UserLicense';

type Props = Translateable & {
  item: UserLicense,
  visible: boolean
};

const ActivationMessage = (props: Props) => (
  <Message
    hidden={!props.visible}
    positive
  >
    { props.item.activated_at && props.item.expires_at && (
      <>
        <Message.Header
          content={props.t('ActivationMessage.labels.licenseActivated')}
        />
        <Message.List>
          <Message.Item
            content={props.t('ActivationMessage.labels.licenseKey', { key: props.item.license_key })}
          />
          <Message.Item
            content={props.t('ActivationMessage.labels.activatedAt', {
              activated_at: DateUtils.getDateTimeFromString(props.item.activated_at, FormatType.date)
            })}
          />
          <Message.Item
            content={props.t('ActivationMessage.labels.expiresAt', {
              expires_at: DateUtils.getDateTimeFromString(props.item.expires_at, FormatType.date)
            })}
          />
        </Message.List>
      </>
    )}
    { !(props.item.activated_at && props.item.expires_at) && (
      <>
        <Message.Header
          content={props.t('ActivationMessage.labels.activationSet')}
        />
        <Message.Content
          content={props.t('ActivationMessage.labels.activationSetMessage')}
        />
      </>
    )}
  </Message>
);

const ActivationMessageComponent: Component = withTranslation()(ActivationMessage);
export default ActivationMessageComponent;
