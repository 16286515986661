// @flow

import React, { useState } from 'react';
import { useEditContainer } from 'react-components';
import { withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import Input from '../components/Input';
import Loader from '../components/Loader';
import Users from '../services/public/Users';

import type { Component } from '../types/Component';

const SAVE_MESSAGE_DELAY = 2000;

const PreferencesForm = withTranslation()(useEditContainer((props) => (
  <section
    className='section-border border-primary'
  >
    <div
      className='container d-flex flex-column'
    >
      { props.saved && (
        <div
          className='alert alert-success fade show mt-4'
          role='alert'
        >
          { props.t('Preferences.messages.saved') }
        </div>
      )}
      <div
        className='row justify-content-center gx-0 min-vh-100'
      >
        <div
          className='col-12 col-md-8 mb-md-0 pb-8 py-md-11'
        >
          <h1
            className='mb-4 fw-bold text-center'
          >
            { props.t('Preferences.title') }
          </h1>
          <form>
            <ul
              className='list-group list-unstyled'
            >
              <li
                className='list-group-item'
              >
                <h6
                  className='d-flex fs-5 mb-0'
                >
                  { props.t('Preferences.labels.subscribe') }
                  <span
                    className='ms-auto'
                  >
                    <div
                      className='form-check form-switch'
                    >
                      <Input
                        checked={props.item.subscribe}
                        className='form-check-input'
                        id='subscribe'
                        name='subscribe'
                        onChange={props.onCheckboxInputChange}
                        type='checkbox'
                        value={props.item.subscribe}
                      />
                    </div>
                  </span>
                </h6>
              </li>
            </ul>
            <div
              className='form-group my-8'
            >
              <button
                className='btn btn-primary lift px-8'
                disabled={props.saving}
                onClick={props.onSave}
                type='button'
              >
                { props.saving && (
                  <Loader />
                )}
                { props.t('Common.buttons.update') }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
)));

const Preferences: Component = () => {
  const [saved, setSaved] = useState(false);
  const { id } = useParams();

  return (
    <PreferencesForm
      defaults={{
        id,
        subscribe: true
      }}
      onSave={(item) => (
        Users
          .save(item)
          .then(() => {
            setSaved(true);
            _.delay(() => setSaved(false), SAVE_MESSAGE_DELAY);
          })
      )}
      saved={saved}
    />
  );
};

export default Preferences;
