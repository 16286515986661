// @flow

import BaseTransform from './BaseTransform';

/**
 * Class for transforming application settings records.
 */
class ApplicationSetting extends BaseTransform {
  /**
   * Returns the application settings parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'application_setting';
  }

  /**
   * Returns the payload keys to set on POST/PUT requests.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'initial_price_id',
      'renewal_price_id',
      'free_license_days',
      'paid_license_days',
      'expiration_days',
      'reminder_days'
    ];
  }
}

const ApplicationSettingTransform: ApplicationSetting = new ApplicationSetting();
export default ApplicationSettingTransform;
