// @flow

import React, { useCallback, useMemo } from 'react';

import type { Component } from '../types/Component';

type Props = {
  className?: string,
  disabled?: boolean,
  error?: boolean,
  name: string,
  required?: boolean,
  onChange: (attribute: string, e: Event, data: any) => void
};

const Input: Component = (props: Props) => {
  const className = useMemo(() => {
    const classNames = [];

    if (props.className) {
      classNames.push(props.className);
    }

    if (props.disabled) {
      classNames.push('disabled');
    }

    if (props.error) {
      classNames.push('is-invalid');
    }

    if (props.required) {
      classNames.push('required');
    }

    return classNames.join(' ');
  }, [props]);

  /**
   * Calls the onTextInputChange prop for the passed attribute and value.
   *
   * @type {(function(*=, *=): void)|*}
   */
  const onChange = useCallback((e) => {
    const { value } = e.target;
    props.onChange(props.name, e, { value });
  }, [props.onChange]);

  return (
    <input
      {...props}
      className={className}
      onChange={onChange}
    />
  );
};

export default Input;
