// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { Toaster, useEditContainer } from 'react-components';
import { useTranslation } from 'react-i18next';
import _ from 'underscore';
import {
  Button,
  Container,
  Form,
  Grid,
  Item,
  Message
} from 'semantic-ui-react';
import ApplicationSettingsService from '../../services/ApplicationSettings';
import NumberUtils from '../../utils/Number';
import PriceInput from '../../components/PriceInput';
import SettingItem from '../../components/SettingItem';
import './ApplicationSettings.css';

import type { Component } from '../../types/Component';
import type { ApplicationSetting } from '../../types/ApplicationSetting';

const ApplicationSettingsForm = useEditContainer((props) => (
  <Container
    as={Form}
    className='application-settings-form'
  >
    <Message
      header={props.t('ApplicationSettings.messages.error.header')}
      hidden={_.isEmpty(props.errors)}
      list={props.errors}
      negative
    />
    <Grid
      as={Item.Group}
      divided='vertically'
      relaxed
    >
      <SettingItem
        header={props.t('ApplicationSettings.labels.initialPrice.header')}
        description={props.t('ApplicationSettings.labels.initialPrice.content')}
      >
        <PriceInput
          error={props.isError('initial_price_id')}
          fluid
          onChange={props.onTextInputChange.bind(this, 'initial_price_id')}
          required={props.isRequired('initial_price_id')}
          value={props.item.initial_price_id || ''}
        />
      </SettingItem>
      <SettingItem
        header={props.t('ApplicationSettings.labels.renewalPrice.header')}
        description={props.t('ApplicationSettings.labels.renewalPrice.content')}
      >
        <PriceInput
          error={props.isError('renewal_price_id')}
          fluid
          onChange={props.onTextInputChange.bind(this, 'renewal_price_id')}
          required={props.isRequired('renewal_price_id')}
          value={props.item.renewal_price_id || ''}
        />
      </SettingItem>
      <SettingItem
        header={props.t('ApplicationSettings.labels.freeTrial.header')}
        description={props.t('ApplicationSettings.labels.freeTrial.content')}
      >
        <Form.Input
          error={props.isError('free_license_days')}
          fluid
          onChange={props.onTextInputChange.bind(this, 'free_license_days')}
          required={props.isRequired('free_license_days')}
          value={props.item.free_license_days || ''}
        />
      </SettingItem>
      <SettingItem
        header={props.t('ApplicationSettings.labels.paidLicense.header')}
        description={props.t('ApplicationSettings.labels.paidLicense.content')}
      >
        <Form.Input
          error={props.isError('paid_license_days')}
          fluid
          onChange={props.onTextInputChange.bind(this, 'paid_license_days')}
          required={props.isError('paid_license_days')}
          value={props.item.paid_license_days || ''}
        />
      </SettingItem>
      <SettingItem
        header={props.t('ApplicationSettings.labels.expiration.header')}
        description={props.t('ApplicationSettings.labels.expiration.content')}
      >
        <Form.Input
          error={props.isError('expiration_days')}
          fluid
          onChange={props.onTextInputChange.bind(this, 'expiration_days')}
          required={props.isRequired('expiration_days')}
          value={props.item.expiration_days || ''}
        />
      </SettingItem>
      <SettingItem
        header={props.t('ApplicationSettings.labels.reminder.header')}
        description={props.t('ApplicationSettings.labels.reminder.content')}
      >
        <Form.Input
          fluid
          onChange={props.onTextInputChange.bind(this, 'reminder_days')}
          value={props.item.reminder_days || ''}
        />
      </SettingItem>
    </Grid>
    <div
      className='button-wrapper'
    >
      <div
        className='button-container'
      >
        <Button
          content={props.t('ApplicationSettings.buttons.save')}
          fluid
          loading={props.saving}
          onClick={props.onSave}
          primary
        />
      </div>
    </div>
  </Container>
));

const ApplicationSettings: Component = () => {
  const [item, setItem] = useState();
  const [saved, setSaved] = useState(false);

  const { t } = useTranslation();

  /**
   * Validates the numeric settings.
   *
   * @type {function(*=): {}}
   */
  const validate = useCallback((setting) => {
    const errors = {};

    validateInteger(setting, 'free_license_days', errors);
    validateInteger(setting, 'paid_license_days', errors);
    validateInteger(setting, 'expiration_days', errors);
    validateInteger(setting, 'reminder_days', errors);

    return errors;
  }, []);

  /**
   * Validates the value for the passed attribute in the passed setting is an integer value. If it is not,
   * an error is added errors object.
   *
   * @param setting
   * @param attribute
   * @param errors
   */
  const validateInteger = (setting: ApplicationSetting, attribute: string, errors) => {
    const value = setting[attribute];

    if (!NumberUtils.isInteger(value)) {
      _.extend(errors, { [attribute]: t(`ApplicationSettings.errors.integer.${attribute}`) });
    }
  };

  /**
   * Loads the first application setting record. There should only ever be one.
   */
  useEffect(() => {
    ApplicationSettingsService
      .fetchAll()
      .then(({ data }) => setItem(_.first(data.application_settings)));
  }, []);

  return (
    <div
      className='application-settings'
    >
      <ApplicationSettingsForm
        item={item}
        onSave={(applicationSetting) => (
          ApplicationSettingsService
            .save(applicationSetting)
            .then(() => setSaved(true))
        )}
        t={t}
        validate={validate}
      />
      { saved && (
        <Toaster
          onDismiss={() => setSaved(false)}
          type='positive'
        >
          <Message.Header
            content={t('ApplicationSettings.messages.save.header')}
          />
          <Message.Content
            content={t('ApplicationSettings.messages.save.content')}
          />
        </Toaster>
      )}
    </div>
  );
};

export default ApplicationSettings;
