// @flow

import React, { type Node } from 'react';
import { Grid, Item } from 'semantic-ui-react';

import type { Component } from '../types/Component';

type Props = {
  children: Node,
  description: string,
  header: string
}

const SettingItem: Component = (props: Props) => (
  <Item
    as={Grid.Row}
  >
    <Item.Content
      as={Grid.Column}
      width={9}
    >
      <Item.Header
        content={props.header}
      />
      <Item.Meta
        content={props.description}
      />
    </Item.Content>
    <Item.Content
      as={Grid.Column}
      style={{
        alignSelf: 'center'
      }}
      width={7}
    >
      { props.children }
    </Item.Content>
  </Item>
);

export default SettingItem;
