// @flow

import axios from 'axios';

class BaseService {
  /**
   * Constructs a new BaseService object. This constructor should never be used directly.
   */
  constructor() {
    // $FlowIgnore
    if (this.constructor === BaseService) {
      throw new TypeError('Abstract class "BaseService" cannot be instantiated directly.');
    }
  }

  /**
   * Calls the POST /api/<resource>/ endpoint with the passed item.
   *
   * @param item
   *
   * @returns {Promise<any>}
   */
  create(item: any): Promise<any> {
    const transform = this.getTransform();

    // $FlowFixMe - Flow doesn't currently support abstract classes
    return axios.post(this.getBaseUrl(), transform.toPayload(item), this.getConfig());
  }

  /**
   * Calls the DELETE /api/<resource>/:id endpoint for the passed item.
   *
   * @param item
   *
   * @returns {Promise<any>}
   */
  delete(item: any): Promise<any> {
    return axios.delete(`${this.getBaseUrl()}/${item.id}`);
  }

  /**
   * Calls the GET /api/<resource>/ endpoint.
   *
   * @param params
   *
   * @returns {Promise<any>}
   */
  fetchAll(params: any): Promise<any> {
    return axios.get(this.getBaseUrl(), { params });
  }

  /**
   * Calls the GET /api/<resource>/:id endpoint.
   *
   * @param id
   *
   * @returns {Promise<any>}
   */
  fetchOne(id: number | string): Promise<any> {
    return axios.get(`${this.getBaseUrl()}/${id}`);
  }

  /**
   * Calls the create/update API endpoint for the passed item.
   *
   * @param item
   *
   * @returns {Promise<any>}
   */
  save(item: any): Promise<any> {
    return item.id ? this.update(item) : this.create(item);
  }

  /**
   * Calls the PUT /api/<resource>/:id endpoint with the passed item.
   *
   * @param item
   *
   * @returns {Promise<any>}
   */
  update(item: any): Promise<any> {
    const transform = this.getTransform();

    // $FlowFixMe - Flow doesn't currently support abstract classes
    return axios.put(`${this.getBaseUrl()}/${item.id}`, transform.toPayload(item), this.getConfig());
  }

  // protected

  /**
   * Returns the API base URL string.
   */
  getBaseUrl(): string {
    // Implemented in concrete classes.
    return '';
  }

  /**
   * Returns the config properties for POST/PUT requests.
   *
   * @returns {null}
   */
  getConfig(): any {
    // Implemented in concrete classes
    return null;
  }

  /**
   * Returns the transform object. This class will be used to generate the object sent on POST/PUT requests.
   */
  getTransform(): any {
    // Implemented in concrete classes.
    return {
      toPayload: (item) => item
    };
  }
}

export default BaseService;
