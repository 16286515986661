// @flow

import React from 'react';
import { useDragDrop } from 'react-components';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes
} from 'react-router-dom';
import ApplicationSettings from './pages/admin/ApplicationSettings';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Buy from './pages/Buy';
import Checkout from './pages/Checkout';
import CheckoutSuccess from './pages/CheckoutSuccess';
import Contact from './pages/Contact';
import Download from './pages/Download';
import Home from './pages/Home';
import Licenses from './pages/admin/Licenses';
import Login from './pages/Login';
import ManageLicenses from './pages/ManageLicenses';
import Preferences from './pages/Preferences';
import Prices from './pages/Prices';
import Renew from './pages/Renew';
import SignUp from './pages/SignUp';
import SignUpSuccess from './pages/SignUpSuccess';
import Subscription from './pages/Subscription';
import Users from './pages/admin/Users';
import './App.css';

import type { Component } from './types/Component';

// Lazy load the layout components
const AdminLayout = React.lazy(() => import('./components/AdminLayout'));
const Layout = React.lazy(() => import('./components/Layout'));

const App = () => (
  <Router>
    <Routes>
      <Route
        element={(
          <React.Suspense
            fallback={<></>}
          >
            <AuthenticatedRoute>
              <AdminLayout />
            </AuthenticatedRoute>
          </React.Suspense>
        )}
        path='/admin'
      >
        <Route
          element={<div>Dashboard</div>}
          path='dashboard'
          index
        />
        <Route
          element={<Users />}
          path='users'
        />
        <Route
          element={<Licenses />}
          path='licenses'
        />
        <Route
          element={<ApplicationSettings />}
          path='settings'
        />
        <Route
          element={<Navigate to='dashboard' />}
          index
        />
      </Route>
      <Route
        element={(
          <React.Suspense
            fallback={<></>}
          >
            <Layout />
          </React.Suspense>
        )}
        path='/'
      >
        <Route
          element={<Home />}
          index
        />
        <Route
          element={<Buy />}
          path='buy'
        />
        <Route
          element={<Checkout />}
          path='checkout'
        />
        <Route
          element={<CheckoutSuccess />}
          path='checkout_success/:id'
        />
        <Route
          element={<Contact />}
          path='/contact'
        />
        <Route
          element={<Download />}
          path='download/:id'
        />
        <Route
          element={<Login />}
          path='login'
        />
        <Route
          element={<ManageLicenses />}
          path='manage/:id'
        />
        <Route
          element={<Preferences />}
          path='preferences/:id'
        />
        <Route
          element={<Prices />}
          path='prices'
        />
        <Route
          element={<Renew />}
          path='renew/:id'
        />
        <Route
          element={<SignUp />}
          path='sign_up'
        />
        <Route
          element={<SignUpSuccess />}
          path='sign_up_success'
        />
        <Route
          element={<Subscription />}
          path='subscriptions/:id'
        />
        <Route
          element={(
            <Navigate
              replace
              to='/'
            />
          )}
          path='*'
        />
      </Route>
    </Routes>
  </Router>
);

const AppComponent: Component = useDragDrop(App);
export default AppComponent;
