// @flow

import BaseConstants from './BaseConstants';
import RenewalTypesData from '../resources/RenewalTypes.json';

class RenewalTypes extends BaseConstants {
  none: string;
  group: string;
  independent: string;
}

const RenewalTypesConstants: RenewalTypes = new RenewalTypes(RenewalTypesData);
export default RenewalTypesConstants;
