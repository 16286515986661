// @flow

import FilterService from './FilterService';
import LicenseGroup from '../transforms/LicenseGroup';

/**
 * Class for handling all license group API requests.
 */
class LicenseGroups extends FilterService {
  /**
   * Returns the license groups base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/license_groups';
  }

  /**
   * Returns the license groups transform.
   *
   * @returns {LicenseGroup}
   */
  getTransform(): * {
    return LicenseGroup;
  }
}

const LicenseGroupsService: LicenseGroups = new LicenseGroups();
export default LicenseGroupsService;
