// @flow

import BaseService from '../BaseService';

class Payments extends BaseService {
  getBaseUrl(): string {
    return '/api/public/payments';
  }

  getTransform(): * {
    return {
      toPayload: (params) => params
    };
  }
}

const PaymentsService: Payments = new Payments();
export default PaymentsService;
