// @flow

/**
 * Uses the HubSpot API to create a contact form.
 *
 * @param target
 */
const createForm = (target: string) => {
  if (window.hbspt) {
    window.hbspt.forms.create({
      formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
      portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
      region: process.env.REACT_APP_HUBSPOT_REGION,
      target
    });
  }
};

export default {
  createForm
};
