// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Component } from '../types/Component';

type Props = {
  content: string,
  header: string,
  icon?: string,
  url: string
};

const CardLink: Component = withTranslation()((props: Props) => (
  <Link
    as='div'
    className='card card-border border-dark shadow-lg mb-6 mb-md-8 mb-lg-0 lift lift-lg'
    to={props.url}
  >
    <div
      className='card-body text-center'
    >
      { props.icon && (
        <div
          className='icon-circle bg-dark text-white mb-5'
        >
          <i
            className={`fe ${props.icon}`}
          />
        </div>
      )}
      <h4
        className='fw-bold'
      >
        { props.header }
      </h4>
      <p
        className='text-gray-700 mb-5'
      >
        { props.content }
      </p>
    </div>
  </Link>
));

export default CardLink;
