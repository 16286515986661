// @flow

import BaseTransform from './BaseTransform';

/**
 * Class for handling transforming license group objects.
 */
class LicenseGroup extends BaseTransform {
  /**
   * Returns the license group child attributes hash.
   *
   * @returns {{user_licenses: string[]}}
   */
  getChildAttributes(): { [string]: Array<string> } {
    return {
      user_licenses: ['id', '_destroy']
    };
  }

  /**
   * Returns the license group parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'license_group';
  }

  /**
   * Returns the license group payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'user_id',
      'name',
      'license_count',
      'renewal_type'
    ];
  }
}

const LicenseGroupTransform: LicenseGroup = new LicenseGroup();
export default LicenseGroupTransform;
