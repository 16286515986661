// @flow

import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Component } from '../types/Component';

const FreeTrial: Component = withTranslation()((props) => (
  <section
    className={props.className}
  >
    <div
      className='container'
    >
      <div
        className='row justify-content-center'
      >
        <div
          className='col-12 col-md-10 text-center'
        >
          <span
            className='badge rounded-pill bg-gray-700-soft mb-4'
          >
            <span
              className='h6 fw-bold text-uppercase'
            >
              { props.t('FreeTrial.label') }
            </span>
          </span>
          <h1
            className='display-4'
          >
            <Trans
              i18nKey='FreeTrial.header'
            >
              Start your project today with
              <span
                className='text-success'
              >
                FairCopy
              </span>
            </Trans>
          </h1>
          <p
            className='fs-lg text-muted mb-6 mb-md-8'
          >
            { props.t('FreeTrial.content') }
          </p>
          <Link
            className='btn btn-success lift'
            to='/sign_up'
          >
            { props.t('Home.buttons.trial') }
            <i className='fe fe-arrow-right d-none d-md-inline ms-3' />
          </Link>
        </div>
      </div>
    </div>
  </section>
));

export default FreeTrial;
