// @flow

import BaseTransform from './BaseTransform';

/**
 * Class for handling transforming user license objects.
 */
class UserLicense extends BaseTransform {
  /**
   * Returns the user license parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'user_license';
  }

  /**
   * Returns the user license payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'user_id',
      'renewal_type',
      'machine_uuid'
    ];
  }
}

const UserLicenseTransform: UserLicense = new UserLicense();
export default UserLicenseTransform;
