// @flow

import React from 'react';

import type { Component } from '../types/Component';

type Props = {
  error: boolean,
  message: string
};

const FormError: Component = (props: Props) => {
  if (!props.error) {
    return null;
  }

  return (
    <div
      className='invalid-feedback d-block'
    >
      { props.message }
    </div>
  );
};

export default FormError;
