// @flow

import React, { useMemo } from 'react';
import { AssociatedDropdown } from 'react-components';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import {
  Button,
  Confirm,
  Form,
  Grid,
  Header,
  Modal
} from 'semantic-ui-react';
import _ from 'underscore';
import ActivationMessage from './ActivationMessage';
import RenewalTypes from '../constants/RenewalTypes';
import User from '../transforms/User';
import UserModal from './UserModal';
import UsersService from '../services/Users';

import type { EditContainerProps } from 'react-components/types';
import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';
import type { UserLicense } from '../types/UserLicense';

type Props = EditContainerProps & Translateable & {
  item: UserLicense,
  onCancel: () => void,
  open: boolean
};

const UserLicenseModal = (props: Props) => {
  const isActivated = useMemo(() => props.item.activate || props.item.activated_at, [props.item]);

  return (
    <Modal
      as={Form}
      centered={false}
      className='user-license-modal'
      noValidate
      open
    >
      <Modal.Header>
        <Grid
          columns={2}
        >
          <Grid.Column
            textAlign='left'
          >
            <Header
              content={props.item.id
                ? props.t('UserLicenseModal.title.edit')
                : props.t('UserLicenseModal.title.add')}
            />
          </Grid.Column>
          <Grid.Column
            textAlign='right'
          >
            <Button
              basic
              color={isActivated ? 'green' : undefined}
              content={props.t('ActivateButton.content')}
              disabled={isActivated}
              icon='send'
              onClick={props.onCheckboxInputChange.bind(this, 'activate')}
            />
            <Confirm
              centered={false}
              content={(
                <Modal.Content>
                  <Form>
                    <Form.Input
                      error={props.isError('machine_uuid')}
                      label={props.t('ActivateButton.labels.machine')}
                      required={props.isRequired('machine_uuid')}
                      onChange={props.onTextInputChange.bind(this, 'machine_uuid')}
                      value={props.item.machine_uuid || ''}
                    />
                  </Form>
                </Modal.Content>
              )}
              header={props.t('ActivateButton.confirm.header', { key: props.item.license_key })}
              noValidate
              onCancel={props.onCancel}
              onConfirm={props.onSave}
              open={props.open}
            />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <ActivationMessage
          item={props.item}
          visible={isActivated}
        />
        { props.item.activate && (
          <Form.Input
            action={{
              color: 'blue',
              content: props.t('UserLicenseModal.actions.generate'),
              icon: 'refresh',
              labelPosition: 'right',
              onClick: () => props.onTextInputChange('machine_uuid', null, { value: uuid() })
            }}
            error={props.isError('machine_uuid')}
            label={props.t('UserLicenseModal.labels.machine')}
            onChange={props.onTextInputChange.bind(this, 'machine_uuid')}
            required
            value={props.item.machine_uuid || ''}
          />
        )}
        <Form.Input
          error={props.isError('user_id')}
          label={props.t('UserLicenseModal.labels.user')}
          required={props.isRequired('user_id')}
        >
          <AssociatedDropdown
            collectionName='users'
            modal={{
              component: UserModal,
              onSave: (user) => UsersService.save(user).then(({ data }) => data.user),
              props: {
                defaults: {
                  customer: true
                }
              }
            }}
            onSearch={(search) => UsersService.fetchAll({ search, sort_by: 'name' })}
            onSelection={props.onAssociationInputChange.bind(this, 'user_id', 'user')}
            renderOption={(user) => User.toDropdown(user)}
            searchQuery={props.item.user && props.item.user.name}
            value={props.item.user_id}
          />
        </Form.Input>
        <Form.Dropdown
          error={props.isError('renewal_type')}
          label={props.t('UserLicenseModal.labels.renewalType')}
          required={props.isRequired('renewal_type')}
          onChange={props.onTextInputChange.bind(this, 'renewal_type')}
          options={_.map(RenewalTypes.getValues(), (rt) => ({
            key: rt.id,
            value: rt.id,
            text: rt.shortText
          }))}
          selection
          value={props.item.renewal_type || ''}
        />
      </Modal.Content>
      { props.children }
    </Modal>
  );
};

const UserLicenseModalComponent: Component = withTranslation()(UserLicenseModal);
export default UserLicenseModalComponent;
