// @flow

import React, { useState } from 'react';
import Script from '../components/Script';
import { withTranslation } from 'react-i18next';
import HubSpot from '../utils/HubSpot';
import Loader from '../components/Loader';

import type { Component } from '../types/Component';

const FORM_ID = 'hubspotForm';
const TARGET = `#${FORM_ID}`;

const Contact: Component = withTranslation()((props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div>
      <Script
        onLoad={() => {
          setLoading(false);
          HubSpot.createForm(TARGET);
        }}
        src='https://js.hsforms.net/forms/shell.js'
      />
      <section
        className='section-border border-primary'
      >
        <div
          className='container d-flex flex-column py-12'
        >
          <div
            className='row justify-content-center gx-0 min-vh-100'
          >
            <div
              className='col-12 col-md-8'
            >
              <h1
                className='mb-4 fw-bold text-center'
              >
                { props.t('Contact.header') }
              </h1>
              { loading && (
                <Loader />
              )}
              <div
                id={FORM_ID}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Contact;
