// @flow

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Errors from '../components/Errors';
import Loader from '../components/Loader';
import UserLicenses from '../services/public/UserLicenses';

import type { Component } from '../types/Component';

const Renew: Component = () => {
  const [errors, setErrors] = useState();
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);

    UserLicenses
      .renew(id)
      .then(({ data }) => navigate(data.url))
      .catch(({ response: { data } }) => setErrors(data.errors))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <section
      className='pt-9 pt-md-8 pb-12'
    >
      <div
        className='container'
      >
        <div
          className='row justify-content-center gx-0 min-vh-100'
        >
          { loading && (
            <Loader />
          )}
          { errors && (
            <Errors
              className='col-12 col-md-8 mb-auto mb-md-0 pb-8 py-6 py-md-11'
              errors={errors}
              message={t('Renew.errors')}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Renew;
