// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  className?: string,
  url: string
};

const ManageSubscription: Component = withTranslation()((props: Props) => (
  <div
    className={props.className}
  >
    <span
      className='badge rounded-pill bg-primary-soft mb-3'
    >
      <span
        className='h6 text-uppercase'
      >
        { props.t('ManageSubscription.tag') }
      </span>
    </span>
    <h2>
      { props.t('ManageSubscription.header') }
      <br />
      <span
        className='text-primary'
      >
        { props.t('ManageSubscription.subheader') }
      </span>
      .
    </h2>
    <p
      className='fs-lg text-gray-700 mb-8 mb-md-0'
    >
      { props.t('ManageSubscription.content') }
    </p>
    <a
      className='btn btn-primary shadow lift me-1 mt-7'
      href={props.url}
    >
      { props.t('ManageSubscription.buttons.manage') }
      <i
        className='fe fe-arrow-right d-none d-md-inline ms-3'
      />
    </a>
  </div>
));

export default ManageSubscription;
