// @flow

import axios from 'axios';
import BaseService from '../BaseService';

/**
 * Class for handling all public user_license requests.
 */
class UserLicenses extends BaseService {
  /**
   * Activates the passed license key.
   *
   * @param licenseKey
   * @param machineUuid
   *
   * @returns {Promise<any>}
   */
  activate(licenseKey: string, machineUuid: string): Promise<any> {
    return axios.put(`${this.getBaseUrl()}/${licenseKey}`, { machine_uuid: machineUuid }, this.getConfig());
  }

  /**
   * Returns the public user_licenses API base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/public/user_licenses';
  }

  /**
   * Returns the user licenses transform.
   *
   * @returns {{toPayload: (function(*): *)}}
   */
  getTransform(): * {
    return {
      toPayload: (params) => params
    };
  }

  /**
   * Renews the passed license key.
   *
   * @param licenseKey
   *
   * @returns {Promise<any>}
   */
  renew(licenseKey: string): Promise<any> {
    return axios.get(`${this.getBaseUrl()}/${licenseKey}/renew`);
  }

  /**
   * Creates a free trial license for the passed user.
   *
   * @param user
   *
   * @returns {Promise<any>}
   */
  trial(user: any): Promise<any> {
    return axios.post(`${this.getBaseUrl()}/trial`, { user });
  }
}

const UserLicensesService: UserLicenses = new UserLicenses();
export default UserLicensesService;
