// @flow

import _ from 'underscore';

import type { Price } from '../types/Price';

type CalculateTotalType = (price: Price, quantity: number) => number;

const calculateTotal: CalculateTotalType = (price, quantity) => {
  let total = 0;

  if (price.unit_amount) {
    total = price.unit_amount;
  } else if (price.tiers) {
    let tier = _.find(price.tiers, (t) => t.up_to >= quantity);

    if (!tier) {
      tier = _.findWhere(price.tiers, { up_to: null });
    }

    if (tier) {
      total = tier.unit_amount;
    }
  }

  return total;
};

type FormatTotalType = (amount: number, options?: any) => string;

const DefaultFormatOptions = {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
};

const formatTotal: FormatTotalType = (amount, options = {}) => (
  (amount / 100.0).toLocaleString('en-US', _.defaults(options, DefaultFormatOptions))
);

type GetTotalType = (price: Price, quantity: number) => string;

const getTotal: GetTotalType = (price, quantity) => formatTotal(calculateTotal(price, quantity));

type GetCheckoutUrl = (priceId: string, quantity: number, renewalType: string) => string;

const getCheckoutUrl: GetCheckoutUrl = (priceId, quantity, renewalType) => (
  `/checkout?price_id=${priceId}&quantity=${quantity}&renewal_type=${renewalType}`
);

export default {
  calculateTotal,
  formatTotal,
  getCheckoutUrl,
  getTotal
};
