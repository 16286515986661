// @flow

import axios from 'axios';
import _ from 'underscore';
import BaseService from './BaseService';

const FilterKeys = {
  associationColumn: 'association_column',
  associationName: 'association_name',
  attributeName: 'attribute_name',
  baseClass: 'base_class',
  operator: 'operator',
  type: 'type',
  value: 'value'
};

class FilterService extends BaseService {
  /**
   * Calls the POST /api/<resource>/search endpoint with the passed parameters.
   *
   * @param params
   *
   * @returns {Promise<any>}
   */
  search(params: any): Promise<any> {
    return axios.post(`${this.getBaseUrl()}/search`, this.getFilterParams(params));
  }

  /**
   * Returns the parameters for the search.
   *
   * @param params
   *
   * @returns {*&{filters}}
   */
  getFilterParams(params: any): any {
    return {
      ...params,
      filters: _.map(params.filters, (filter) => this.transformKeys(filter))
    };
  }

  /**
   * Transforms the keys for the passed filter.
   *
   * @param filter
   *
   * @returns {{}}
   */
  transformKeys(filter: any): any {
    const newFilter = {};

    _.each(_.keys(FilterKeys), (key) => {
      newFilter[FilterKeys[key]] = filter[key];
    });

    return newFilter;
  }
}

export default FilterService;
