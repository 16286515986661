// @flow

import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

const SignUpSuccess: Component = withTranslation()((props: Translateable) => (
  <section
    className='section-border border-primary'
  >
    <div
      className='container d-flex flex-column'
    >
      <div
        className='row justify-content-center gx-0 min-vh-100'
      >
        <div
          className='col-12 col-md-8 mb-auto mb-md-0 pb-8 py-8 py-md-11 text-center'
        >
          <span
            className='icon-circle bg-primary text-white mb-6'
          >
            <i className='fe fe-mail' />
          </span>
          <h1
            className='display-3 fw-bold'
          >
            { props.t('SignUpSuccess.header') }
          </h1>
          <p
            className='mb-5 text-center text-muted pt-4'
          >
            { props.t('SignUpSuccess.content') }
          </p>
          <div
            className='text-center'
          >
            <Link
              className='btn btn-primary shadow lift mt-3'
              to='/'
            >
              <i
                className='fe fe-home me-3'
              />
              { props.t('SignUpSuccess.buttons.home') }
            </Link>
          </div>
        </div>
      </div>
    </div>
  </section>
));

export default SignUpSuccess;
