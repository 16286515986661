// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { useEditContainer } from 'react-components';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import _ from 'underscore';
import Customers from '../services/stripe/Customers';
import Eula from '../components/Eula';
import FormError from '../components/FormError';
import Input from '../components/Input';
import Loader from '../components/Loader';
import Modal from '../components/Modal';
import PaymentIntents from '../services/stripe/PaymentIntents';
import PaymentsService from '../services/public/Payments';
import PriceUtils from '../utils/Price';
import Prices from '../services/stripe/Prices';
import { ReactComponent as Cart } from '../icons/duotone-icons/shopping/cart-1.svg';
import RenewalTypes from '../constants/RenewalTypes';
import Subscriptions from '../services/stripe/Subscriptions';
import scss from '../scss/export.module.scss';
import UserLicenses from '../services/public/UserLicenses';
import withStripe from '../hooks/Stripe';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

const BillingKeys = [
  'email',
  'name'
];

const CardErrors = [
  'incomplete_number',
  'card_declined',
  'expired_card',
  'incorrect_cvc',
  'processing_error',
  'incorrect_number'
];

type CheckoutCardProps = Translateable & {
  priceId: string,
  quantity: number
};

/**
 * Renders the CheckoutCard component. This component is responsible for displaying the per license price, quantity,
 * and total being purchased.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const CheckoutCard = withTranslation()((props: CheckoutCardProps) => {
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState();

  /**
   * Loads the price and sets it on the state.
   */
  useEffect(() => {
    setLoading(true);

    Prices
      .fetchOne(props.priceId)
      .then(({ data }) => setPrice(data.price))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      { loading && (
        <Loader />
      )}
      { price && (
        <div
          className='card border-1 shadow'
        >
          <div
            className='card-body'
          >
            <ul
              className='list-group list-group-flush list-unstyled'
            >
              <li
                className='list-item'
              >
                <h6
                  className='text-uppercase d-flex'
                >
                  { props.t('Checkout.labels.license') }
                  <span
                    className='ms-auto'
                  >
                    { PriceUtils.getTotal(price, props.quantity) }
                  </span>
                </h6>
              </li>
              <li>
                <h6
                  className='text-uppercase d-flex'
                >
                  { props.t('Checkout.labels.quantity') }
                  <span
                    className='ms-auto'
                  >
                    { props.quantity }
                  </span>
                </h6>
              </li>
              <li
                className='pt-6'
              >
                <h6
                  className='text-uppercase d-flex fw-bold'
                >
                  { props.t('Checkout.labels.total') }
                  <span
                    className='ms-auto'
                  >
                    { PriceUtils.formatTotal(PriceUtils.calculateTotal(price, props.quantity) * props.quantity) }
                  </span>
                </h6>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
});

/**
 * Renders the CheckoutForm component. This component uses the EditContainer from 'react-components' to display a
 * form to collection user and credit card information.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const CheckoutForm = withTranslation()(useEditContainer((props) => {
  const [cardFocus, setCardFocus] = useState(false);

  let cardRef;

  /**
   * Sets the border color for the card input element.
   *
   * @type {unknown}
   */
  const borderColor = useMemo(() => {
    const isError = !_.isEmpty(_.filter(CardErrors, (e) => props.isError(e)));

    if (isError) {
      return scss.errorColor;
    }

    if (cardFocus) {
      return scss.primaryColor;
    }

    return undefined;
  }, [cardFocus, props.isError]);

  return (
    <form>
      <div
        className='form-group mb-5'
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className='form-label'
          htmlFor='email'
        >
          { props.t('Checkout.labels.email') }
        </label>
        <Input
          autoFocus
          className='form-control'
          error={props.isError('email') || props.isError('email_format')}
          id='email'
          name='email'
          onChange={props.onTextInputChange}
          placeholder={props.t('Checkout.placeholders.email')}
          required={props.isRequired('email')}
          type='email'
          value={props.item.email || ''}
        />
        <div
          className='invalid-feedback'
        >
          { props.t('Checkout.errors.email') }
        </div>
      </div>
      <div
        className='form-group mb-6'
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className='form-label'
          htmlFor='name'
        >
          { props.t('Checkout.labels.name') }
        </label>
        <Input
          className='form-control'
          error={props.isError('name')}
          id='name'
          name='name'
          onChange={props.onTextInputChange}
          placeholder={props.t('Checkout.placeholders.name')}
          required={props.isError('required')}
          type='text'
          value={props.item.name || ''}
        />
        <div
          className='invalid-feedback'
        >
          { props.t('Checkout.errors.name') }
        </div>
      </div>
      <div
        className='form-group mb-6'
      >
        <label
          className='form-label'
          htmlFor='card'
          onClick={() => cardRef && cardRef.focus()}
        >
          { props.t('Checkout.labels.card') }
        </label>
        <div
          className='form-control'
          style={{
            borderColor
          }}
        >
          <CardElement
            onReady={(ref) => {
              cardRef = ref;
            }}
            className='card-element'
            onBlur={() => setCardFocus(false)}
            onFocus={() => setCardFocus(true)}
            options={{
              style: {
                base: {
                  color: scss.fontColor,
                  fontFamily: 'HKGroteskPro, serif',
                  fontSize: '17px',
                  fontWeight: 400,
                  '::placeholder': {
                    fontFamily: 'HKGroteskPro, serif',
                    fontSize: '17px',
                    fontWeight: 400,
                    color: scss.formPlaceholder,
                    opacity: 1
                  }
                }
              }
            }}
          />
        </div>
        { _.map(CardErrors, (e) => (
          <FormError
            error={props.isError(e)}
            message={props.t(`Checkout.errors.card.${e}`)}
          />
        ))}
      </div>
      { props.allowSubscription && (
        <div
          className='form-group mb-6 form-check'
        >
          <Input
            className='form-check-input'
            id='subscription'
            name='subscription'
            onChange={props.onCheckboxInputChange}
            type='checkbox'
            value={props.item.subscription}
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className='form-check-label'
            htmlFor='subscription'
          >
            { props.t('Checkout.labels.renew') }
          </label>
        </div>
      )}
      <div
        className='form-group mb-6 form-check'
      >
        <Input
          className='form-check-input'
          error={props.isError('eula')}
          id='eula'
          name='eula'
          onChange={props.onCheckboxInputChange}
          required={props.isRequired('eula')}
          type='checkbox'
          value={props.item.eula}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label
          className='form-check-label'
          htmlFor='eula'
        >
          <Trans
            i18nKey='Checkout.labels.eula'
          >
            I have read and agree to the terms of the
            <a
              data-bs-toggle='modal'
              data-bs-target='#eulaModal'
              href='#eulaModal'
            >
              End User License Agreement
            </a>
          </Trans>
        </label>
        <div
          className='invalid-feedback'
        >
          { props.t('Checkout.errors.eula') }
        </div>
      </div>
      <div
        className='form-group my-8'
      >
        <button
          className='btn w-100 btn-primary lift'
          disabled={props.saving}
          onClick={props.onSave}
          type='button'
        >
          { props.saving && (
            <Loader />
          )}
          { props.t('Checkout.buttons.confirm') }
        </button>
      </div>
    </form>
  );
}));

/**
 * Renders the Checkout component. This component is responsible for processing the information entered into the
 * CheckoutForm component and re-directing on a successful checkout.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const Checkout = () => {
  const elements = useElements();
  const stripe = useStripe();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const priceId = searchParams.get('price_id');
  const renewalType = searchParams.get('renewal_type');
  const secureId = searchParams.get('secure_id');

  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(Number(searchParams.get('quantity')));

  /**
   * Confirms the payment via the Stripe API.
   *
   * @param item
   * @param customer
   * @param data
   *
   * @returns {Promise<any>}
   */
  const confirmPayment = (item, customer, { data }) => {
    let paymentIntent;

    if (data.payment_intent) {
      paymentIntent = data.payment_intent;
    } else if (data.subscription) {
      paymentIntent = data.subscription.latest_invoice.payment_intent;
    }

    if (!paymentIntent) {
      return Promise.resolve();
    }

    const params = {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: _.pick(item, BillingKeys)
      }
    };

    return stripe
      .confirmCardPayment(paymentIntent.client_secret, params)
      .then(onPaymentComplete.bind(this, customer, paymentIntent.id));
  };

  /**
   * Creates the payment intent for the passed customer.
   *
   * @param item
   * @param customer
   *
   * @returns {Q.Promise<unknown>}
   */
  const createPaymentIntent = (item, { data: { customer } }) => (
    PaymentIntents
      .create({
        customer_id: customer.id,
        price_id: priceId,
        quantity,
        secure_id: secureId
      })
      .then(confirmPayment.bind(this, item, customer))
  );

  /**
   * Creates the subscription for the passed customer.
   *
   * @param item
   * @param customer
   *
   * @returns {Q.Promise<unknown>}
   */
  const createSubscription = (item, { data: { customer } }) => (
    Subscriptions
      .create({
        customer_id: customer.id,
        price_id: priceId,
        quantity,
        secure_id: secureId
      })
      .then(confirmPayment.bind(this, item, customer))
  );

  /**
   * Completes the payment by display an error or navigating to the success page.
   *
   * @param customer
   * @param paymentIntent
   * @param error
   */
  const onPaymentComplete = (customer, paymentIntentId, { paymentIntent, error }) => {
    /*
     * If an error is returned, the payment was not successful. Cancel the payment intent (a new one will be created
     * if the user tries again) and construct an error response.
     */
    if (error) {
      return PaymentIntents.delete({ id: paymentIntentId }).then(() => (
        // eslint-disable-next-line prefer-promise-reject-errors
        Promise.reject({ response: { data: { errors: { [error.code]: [error.message] } } } })
      ));
    }

    /**
     * If a payment intent is returned, the the payment was successful. Create or update the license and navigate to
     * the payment complete page.
     */
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      const { id, name, email } = customer;

      const attributes = _.defaults({
        id: secureId,
        payment_intent_id: paymentIntent.id,
        quantity,
        renewal_type: renewalType,
        user: {
          ...customer.user || {},
          name,
          email,
          customer: true,
          stripe_id: id
        }
      });

      return PaymentsService
        .save(attributes)
        .then(({ data }) => navigate(`/checkout_success/${paymentIntent.id}?secure_id=${data.secure_id}`));
    }

    return Promise.resolve();
  };

  /**
   * Creates the customer then the subscription or payment intent.
   *
   * @param item
   *
   * @returns {Q.Promise<unknown>}
   */
  const onSubmit = (item) => (
    Customers
      .create(item)
      .then(
        item.subscription
          ? createSubscription.bind(this, item)
          : createPaymentIntent.bind(this, item)
      )
  );

  /**
   * Validates the form on submit.
   *
   * @param item
   *
   * @returns {{}}
   */
  const onValidate = (item) => {
    const errors = {};

    if (!item.eula) {
      _.extend(errors, { eula: t('Checkout.errors.eula') });
    }

    return errors;
  };

  /**
   * Fetches the user licenses for the current secureId, if present.
   */
  useEffect(() => {
    if (secureId) {
      UserLicenses
        .fetchAll({ secure_id: secureId })
        .then(({ data }) => setQuantity(data.user_licenses && data.user_licenses.length));
    }
  }, [secureId]);

  return (
    <>
      <section>
        <div
          className='container d-flex flex-column'
        >
          <div
            className='row row justify-content-center gx-0 min-vh-100'
          >
            <div
              className='col-12'
            >
              <div
                className='container'
              >
                <div
                  className='row'
                >
                  <div
                    className='col-12'
                  >
                    <div
                      className='icon icon-xl text-primary text-center mb-8'
                    >
                      <Cart />
                      <h2>
                        { t('Checkout.header') }
                      </h2>
                    </div>
                  </div>
                </div>
                <div
                  className='row'
                >
                  <div
                    className='col-12 col-md-6 px-6 order-2 order-md-1'
                  >
                    <CheckoutForm
                      allowSubscription={renewalType === RenewalTypes.group || quantity === 1}
                      elements={elements}
                      onSave={onSubmit}
                      renewalType={renewalType}
                      required={['email', 'name']}
                      resolveValidationError={({ key, error }) => ({ [key]: error })}
                      validate={onValidate}
                    />
                  </div>
                  <div
                    className='col-12 col-md-6 px-6 order-1 order-md-2 pb-8'
                  >
                    <CheckoutCard
                      priceId={priceId}
                      quantity={quantity}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className='modal-xl'
        closeable
        id='eulaModal'
        title={t('Common.labels.eula')}
      >
        <Eula />
      </Modal>
    </>
  );
};

const CheckoutPage: Component = withTranslation()(withStripe(Checkout));
export default CheckoutPage;
