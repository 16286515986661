// @flow

import React from 'react';
import { useEditContainer } from 'react-components';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormError from '../components/FormError';
import Input from '../components/Input';
import Loader from '../components/Loader';
import UserLicenses from '../services/public/UserLicenses';

import type { Component } from '../types/Component';

const SignUpForm = withTranslation()(useEditContainer((props) => (
  <section
    className='section-border border-primary'
  >
    <div
      className='container d-flex flex-column'
    >
      <div
        className='row align-items-center justify-content-center gx-0 min-vh-100'
      >
        <div
          className='col-12 col-md-6 mb-md-0 pb-8 py-md-11'
        >
          <h1
            className='mb-4 fw-bold text-center'
          >
            { props.t('SignUp.header') }
          </h1>
          <p
            className='mb-6 text-center text-muted'
          >
            { props.t('SignUp.subheader') }
          </p>
          <form
            className='mb-6'
          >
            <div
              className='form-group'
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className='form-label'
                htmlFor='email'
              >
                { props.t('SignUp.labels.email') }
              </label>
              <Input
                autoFocus
                className='form-control'
                error={props.isError('email') || props.isError('user_exists') || props.isError('user.email')}
                id='email'
                name='email'
                placeholder='name@address.com'
                onChange={props.onTextInputChange}
                required={props.isRequired('email')}
                type='email'
                value={props.item.email || ''}
              />
              <FormError
                error={props.isError('email') || props.isError('user.email')}
                message={props.t('SignUp.errors.email')}
              />
              <FormError
                error={props.isError('user_exists')}
                message={props.t('SignUp.errors.duplicate')}
              />
            </div>
            <div
              className='form-group mb-5'
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label
                className='form-label'
                htmlFor='name'
              >
                { props.t('SignUp.labels.name') }
              </label>
              <Input
                className='form-control'
                error={props.isError('name')}
                id='name'
                name='name'
                placeholder='Enter your name'
                onChange={props.onTextInputChange}
                required={props.isRequired('name')}
                type='text'
                value={props.item.name || ''}
              />
              <div
                className='invalid-feedback'
              >
                { props.t('SignUp.errors.name') }
              </div>
            </div>
            <button
              className='btn w-100 btn-primary'
              disabled={props.saving}
              onClick={props.onSave}
              type='button'
            >
              { props.saving && (
                <Loader />
              )}
              { props.t('SignUp.buttons.signUp') }
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
)));

const SignUp: Component = () => {
  const navigate = useNavigate();

  return (
    <SignUpForm
      onSave={(item) => (
        UserLicenses
          .trial(item)
          .then(() => navigate('/sign_up_success'))
      )}
      required={['name', 'email']}
      resolveValidationError={({ key, error }) => ({ [key]: error })}
    />
  );
};

export default SignUp;
