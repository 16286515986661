// @flow

import React from 'react';
import { AssociatedDropdown, EmbeddedList } from 'react-components';
import { withTranslation } from 'react-i18next';
import { Form, Modal } from 'semantic-ui-react';
import _ from 'underscore';
import DateUtils, { FormatType } from '../utils/Date';
import RenewalTypes from '../constants/RenewalTypes';
import User from '../transforms/User';
import UserModal from './UserModal';
import UsersService from '../services/Users';

import type { EditContainerProps } from 'react-components/types';
import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';
import type { LicenseGroup } from '../types/LicenseGroup';

type Props = EditContainerProps & Translateable & {
  item: LicenseGroup
};

const LicenseGroupModal = (props: Props) => (
  <Modal
    as={Form}
    centered={false}
    className='license-group-modal'
    noValidate
    open
  >
    <Modal.Header
      content={props.item.id
        ? props.t('LicenseGroupModal.title.edit')
        : props.t('LicenseGroupModal.title.add')}
    />
    <Modal.Content
      scrolling={!_.isEmpty(props.item.user_licenses)}
    >
      <Form.Input
        error={props.isError('user_id')}
        label={props.t('LicenseGroupModal.labels.user')}
        required={props.isRequired('user_id')}
      >
        <AssociatedDropdown
          collectionName='users'
          modal={{
            component: UserModal,
            onSave: (user) => UsersService.save(user).then(({ data }) => data.user),
            props: {
              defaults: {
                customer: true
              }
            }
          }}
          onSearch={(search) => UsersService.fetchAll({ search, sort_by: 'name' })}
          onSelection={props.onAssociationInputChange.bind(this, 'user_id', 'user')}
          renderOption={(user) => User.toDropdown(user)}
          searchQuery={props.item.user && props.item.user.name}
          value={props.item.user_id}
        />
      </Form.Input>
      <Form.Input
        error={props.isError('name')}
        label={props.t('LicenseGroupModal.labels.name')}
        onChange={props.onTextInputChange.bind(this, 'name')}
        required={props.isRequired('name')}
        value={props.item.name || ''}
      />
      <Form.Input
        error={props.isError('license_count')}
        label={props.t('LicenseGroupModal.labels.count')}
        onChange={props.onTextInputChange.bind(this, 'license_count')}
        required={props.isRequired('license_count')}
        value={props.item.license_count || ''}
      />
      <Form.Dropdown
        error={props.isError('renewal_type')}
        label={props.t('UserLicenseModal.labels.renewalType')}
        required={props.isRequired('renewal_type')}
        onChange={props.onTextInputChange.bind(this, 'renewal_type')}
        options={_.map(RenewalTypes.getValues(), (rt) => ({
          key: rt.id,
          value: rt.id,
          text: rt.shortText
        }))}
        selection
        value={props.item.renewal_type || ''}
      />
      { !_.isEmpty(props.item.user_licenses) && (
        <EmbeddedList
          actions={[{
            name: 'delete'
          }]}
          columns={[{
            name: 'license_key',
            label: props.t('LicenseGroupModal.userLicenses.columns.licenseKey')
          }, {
            name: 'expires_at',
            label: props.t('LicenseGroupModal.userLicenses.columns.expiresAt'),
            resolve: (ul) => DateUtils.getDateTimeFromString(ul.expires_at, FormatType.date)
          }]}
          items={props.item.user_licenses}
          onDelete={props.onDeleteChildAssociation.bind(this, 'user_licenses')}
        />
      )}
    </Modal.Content>
    { props.children }
  </Modal>
);

const LicenseGroupModalComponent: Component = withTranslation()(LicenseGroupModal);
export default LicenseGroupModalComponent;
