// @flow

import BaseService from '../BaseService';

/**
 * Service class for handling payment intent requests.
 */
class PaymentIntents extends BaseService {
  /**
   * Returns the base URL for the payment intents API.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/stripe/payment_intents';
  }
}

const PaymentIntentsService: PaymentIntents = new PaymentIntents();
export default PaymentIntentsService;
