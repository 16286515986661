// @flow

import _ from 'underscore';

/**
 * Base class for handling constants/lists.
 */
class BaseConstants {
  values: Array<any>;

  /**
   * Constructs a new BaseConstants with the passed JSON data.
   *
   * @param values
   */
  constructor(values: Array<any>) {
    this.values = values;

    _.each(values, (value) => {
      // $FlowIgnore
      this[value.id] = value.id;
    });
  }

  /**
   * Returns the value in the array for the passed ID.
   *
   * @param id
   *
   * @returns {*}
   */
  find(id: string): any {
    return _.find(this.values, { id });
  }

  /**
   * Returns the list of IDs.
   *
   * @returns {*}
   */
  getIds(): Array<string> {
    return _.pluck(this.values, 'id');
  }

  /**
   * Returns all values in the array.
   *
   * @returns {Array<*>}
   */
  getValues(): Array<any> {
    return this.values;
  }

  getDescription(id: string): string {
    const value = this.find(id);
    return value && value.description;
  }

  /**
   * Returns the text for the passed ID.
   *
   * @param id
   *
   * @returns {*}
   */
  getText(id: string): string {
    const value = this.find(id);
    return value && value.shortText;
  }
}

export default BaseConstants;
