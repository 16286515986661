// @flow

import React from 'react';
import { BooleanIcon, ListTable } from 'react-components';
import { withTranslation } from 'react-i18next';
import DateUtils, { FormatType } from '../../utils/Date';
import UserModal from '../../components/UserModal';
import UsersService from '../../services/Users';

import type { Component } from '../../types/Component';
import type { Translateable } from '../../types/Translateable';

const Users = (props: Translateable) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'delete'
    }]}
    addButton={{
      basic: false,
      color: 'green',
      location: 'top'
    }}
    className='users'
    collectionName='users'
    columns={[{
      name: 'name',
      label: props.t('Users.columns.name'),
      sortable: true
    }, {
      name: 'email',
      label: props.t('Users.columns.email'),
      sortable: true
    }, {
      name: 'customer',
      label: props.t('Users.columns.customer'),
      render: (user) => <BooleanIcon value={user.customer} />,
      sortable: true
    }, {
      name: 'unsubscribed_at',
      label: props.t('Users.columns.unsubscribedAt'),
      resolve: (user) => DateUtils.getDateTimeFromString(user.unsubscribed_at, FormatType.date),
      sortable: true
    }]}
    modal={{
      component: UserModal,
      props: {
        onInitialize: (id) => UsersService.fetchOne(id).then(({ data }) => data.user),
        required: ['name', 'email'],
        defaults: {
          customer: true
        }
      }
    }}
    onDelete={(user) => UsersService.delete(user)}
    onLoad={(params) => UsersService.fetchAll(params)}
    onSave={(user) => UsersService.save(user)}
  />
);

const UsersPage: Component = withTranslation()(Users);
export default UsersPage;
