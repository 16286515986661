// @flow

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useEditContainer } from 'react-components';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'underscore';
import FormError from '../components/FormError';
import Input from '../components/Input';
import Loader from '../components/Loader';
import PriceUtils from '../utils/Price';
import Prices from '../services/stripe/Prices';
import { ReactComponent as Minus } from '../icons/duotone-icons/code/minus.svg';
import { ReactComponent as Plus } from '../icons/duotone-icons/code/plus.svg';
import { ReactComponent as Group } from '../icons/duotone-icons/communication/group.svg';
import RenewalTypes from '../constants/RenewalTypes';

import type { EditContainerProps } from 'react-components/types';
import type { Component } from '../types/Component';
import type { Price } from '../types/Price';
import type { Translateable } from '../types/Translateable';

type Props = EditContainerProps & Translateable & {
  price: Price,
  item: {
    quantity: number,
    renewalType: string
  }
};

const DEFAULT_QUANTITY = 2;
const MIN_WIDTH = 50;
const WIDTH_INCREMENT = 15;

const BuyForm = withTranslation()(useEditContainer((props: Props) => {
  /**
   * Dynamically sets the width for the quantity input element.
   *
   * @type {string}
   */
  const width = useMemo(() => (
    `${Math.max(MIN_WIDTH, (props.item.quantity || '').toString().length * WIDTH_INCREMENT)}px`
  ), [props.item.quantity]);

  /**
   * Sets the quantity to the passed value. If the passed value is not a valid integer, it is set to undefined.
   *
   * @type {(function(*=, *=, {value?: *}): void)|*}
   */
  const onSetQuantity = useCallback((name, e, { value }) => {
    const newValue = Number.parseInt(value, 10);

    if (!Number.isNaN(newValue)) {
      props.onTextInputChange(name, e, { value: newValue });
    } else {
      props.onTextInputChange(name, e, { value: undefined });
    }
  }, [props.onTextInputChange]);

  /**
   * Increments the quantity value by the passed increment.
   *
   * @type {(function(*): void)|*}
   */
  const onQuantityChange = useCallback((increment) => {
    const newQuantity = props.item.quantity + increment;

    if (newQuantity >= DEFAULT_QUANTITY) {
      props.onTextInputChange('quantity', null, { value: newQuantity });
    } else {
      props.onTextInputChange('quantity', null, { value: DEFAULT_QUANTITY });
    }
  }, [props.item.quantity, props.onTextInputChange]);

  return (
    <form>
      <div
        className='container'
      >
        <div
          className='row justify-content-center'
        >
          <div
            className='col-12 col-md-6 position-md-sticky d-flex align-items-center justify-content-center vh-md-100'
          >
            <div
              className='py-0 pb-12'
            >
              <div
                className='icon icon-xl text-primary text-center mb-6'
              >
                <Group />
                <h2
                  className='fw-bold'
                >
                  { props.t('Buy.header') }
                </h2>
              </div>
              <p
                className='fs-lg mb-8 text-muted'
              >
                { props.t('Buy.subheader') }
              </p>
              { props.loading && (
                <Loader />
              )}
              { props.price && (
                <>
                  <ul
                    className='list-group list-group-flush'
                  >
                    <li
                      className='list-group-item'
                    >
                      <h6
                        className='d-flex mb-0 text-uppercase'
                      >
                        { props.t('Buy.labels.price') }
                        <span
                          className='ms-auto'
                        >
                          { PriceUtils.getTotal(props.price, props.item.quantity) }
                        </span>
                      </h6>
                    </li>
                    <li
                      className='list-group-item'
                    >
                      <h6
                        className='d-flex mb-0 text-uppercase align-items-center'
                      >
                        { props.t('Buy.labels.quantity') }
                        <span
                          className='ms-auto d-flex'
                        >
                          <button
                            className='btn icon icon-xs text-primary p-2'
                            disabled={props.item.quantity === DEFAULT_QUANTITY}
                            onClick={onQuantityChange.bind(this, -1)}
                            type='button'
                          >
                            <Minus />
                          </button>
                          <Input
                            className='form-control form-control-xs'
                            error={props.isError('quantity')}
                            name='quantity'
                            onChange={onSetQuantity}
                            style={{
                              width
                            }}
                            value={props.item.quantity}
                          />
                          <button
                            className='btn icon icon-xs text-primary p-2'
                            onClick={onQuantityChange.bind(this, 1)}
                            type='button'
                          >
                            <Plus />
                          </button>
                        </span>
                      </h6>
                      <FormError
                        error={props.isError('quantity')}
                        message={props.t('Buy.errors.quantity')}
                      />
                    </li>
                    <li
                      className='list-group-item'
                    >
                      <h6
                        className='d-flex mb-0 text-uppercase align-items-center'
                      >
                        { props.t('Buy.labels.renewalType') }
                        <span
                          className='ms-auto'
                        >
                          <select
                            className='form-select form-select-xs'
                            onChange={(e) => props.onTextInputChange('renewalType', e, { value: e.target.value })}
                          >
                            { _.map(RenewalTypes.getValues(), (rt) => (
                              <option
                                selected={rt.id === props.item.renewalType}
                                value={rt.id}
                              >
                                { rt.shortText }
                              </option>
                            ))}
                          </select>
                        </span>
                      </h6>
                      <p
                        className='fs-sm text-muted mt-4'
                      >
                        { RenewalTypes.getDescription(props.item.renewalType) }
                      </p>
                    </li>
                    <li
                      className='list-group-item'
                    >
                      <h6
                        className='d-flex mb-0 text-uppercase fw-bold'
                      >
                        { props.t('Buy.labels.total') }
                        <span
                          className='ms-auto'
                        >
                          { _.isNumber(props.item.quantity) && props.item.quantity >= DEFAULT_QUANTITY && (
                            PriceUtils.formatTotal(
                              PriceUtils.calculateTotal(props.price, props.item.quantity) * props.item.quantity
                            )
                          )}
                        </span>
                      </h6>
                    </li>
                  </ul>
                  <button
                    className='btn btn-primary shadow lift me-1 w-100 mt-7'
                    onClick={props.onSave}
                    type='button'
                  >
                    { props.t('Buy.buttons.checkout') }
                    <i
                      className='fe fe-arrow-right d-none d-md-inline ms-3'
                    />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}));

const Buy: Component = () => {
  const [price, setPrice] = useState();
  const navigate = useNavigate();

  /**
   * Loads the initial price.
   */
  useEffect(() => {
    Prices
      .fetchInitialPrice()
      .then(({ data }) => setPrice(data.price));
  }, []);

  if (!price) {
    return (
      <Loader />
    );
  }

  return (
    <BuyForm
      item={{
        quantity: DEFAULT_QUANTITY,
        renewalType: RenewalTypes.independent
      }}
      price={price}
      onSave={({ quantity, renewalType }) => {
        navigate(PriceUtils.getCheckoutUrl(price.id, quantity, renewalType));
        return Promise.resolve();
      }}
      validate={(item) => {
        const errors = {};

        if (!item.quantity || !_.isNumber(item.quantity) || item.quantity < DEFAULT_QUANTITY) {
          _.extend(errors, { quantity: 'TEST!' });
        }

        return errors;
      }}
    />
  );
};

export default Buy;
