// @flow

import React from 'react';
import type { Component } from '../types/Component';
import { withTranslation } from 'react-i18next';

const Loader: Component = withTranslation()((props) => (
  <div
    className='col-12 text-center'
  >
    <div
      className='spinner-border'
      role='status'
    >
      <span
        className='visually-hidden'
      >
        { props.t('Common.labels.loading') }
      </span>
    </div>
  </div>
));

export default Loader;
