// @flow

import axios from 'axios';
import BaseService from '../BaseService';

/**
 * Service class for handling price requests.
 */
class Prices extends BaseService {
  /**
   * Returns the base URL for the prices API.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/stripe/prices';
  }

  /**
   * Returns the initial price for the product.
   *
   * @returns {Promise<any>}
   */
  fetchInitialPrice(): Promise<any> {
    return axios.get(`${this.getBaseUrl()}/initial`);
  }

  /**
   * Returns the renewal price for the product.
   *
   * @returns {Promise<any>}
   */
  fetchRenewalPrice(): Promise<any> {
    return axios.get(`${this.getBaseUrl()}/renewal`);
  }
}

const PricesService: Prices = new Prices();
export default PricesService;
