// @flow

import _ from 'underscore';

import type { UserLicense } from '../types/UserLicense';

/**
 * Returns the download URL for the passed user license.
 *
 * @param userLicense
 *
 * @returns {string}
 */
const getDownloadUrl = (userLicense: UserLicense, reload: boolean = false): string => (
  `/download/${userLicense.secure_id}?reload=${reload.toString()}`
);

/**
 * Returns the payment URL for the passed user license.
 *
 * @param userLicense
 *
 * @returns {string}
 */
const getPaymentUrl = (userLicense: UserLicense, reload: boolean = false): string => (
  `/checkout_success/${userLicense.payment_intent_id}?secure_id=${getSecureId(userLicense)}&reload=${reload.toString()}`
);

/**
 * Returns the secure ID for the passed user license.
 *
 * @param userLicense
 *
 * @returns {string}
 */
const getSecureId = (userLicense: UserLicense): string => (
  !_.isEmpty(userLicense.license_group) ? userLicense.license_group.secure_id : userLicense.secure_id
);

/**
 * Returns the subscription URL for the passed user license.
 *
 * @param userLicense
 *
 * @param reload
 *
 * @returns {`/subscriptions/${string}?reload=${string}`}
 */
const getSubscriptionUrl = (userLicense: UserLicense, reload: boolean): string => (
  `/subscriptions/${userLicense.secure_id}?reload=${reload.toString()}`
);

export default {
  getDownloadUrl,
  getPaymentUrl,
  getSecureId,
  getSubscriptionUrl
};
