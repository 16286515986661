// @flow

import React, { type Element } from 'react';
import { Navigate } from 'react-router-dom';
import Session from '../services/Session';

import type { Component } from '../types/Component';

type Props = {
  children: Element<any>
};

const AuthenticatedRoute: Component = (props: Props) => {
  if (!Session.isAuthenticated()) {
    return <Navigate to='/' />;
  }

  return props.children;
};

export default AuthenticatedRoute;
