// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import _ from 'underscore';
import CardLink from '../components/CardLink';
import DateUtils, { FormatType } from '../utils/Date';
import Loader from '../components/Loader';
import PaymentIntents from '../services/stripe/PaymentIntents';
import PriceUtils from '../utils/Price';
import { ReactComponent as Curve1 } from '../shapes/curves/curve-1.svg';

import type { Component } from '../types/Component';

type OrderRowType = {
  label: string,
  value: string
};

/**
 * Renders the order row component with label and value props.
 *
 * @param label
 * @param value
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const OrderRow: Component = ({ label, value }: OrderRowType) => (
  <li
    className='list-group-item d-flex justify-content-between align-items-center'
  >
    { label }
    <div className='text-muted ms-auto'>
      <span
        className='fs-sm me-4 d-md-inline'
      >
        { value }
      </span>
    </div>
  </li>
);

/**
 * Renders the checkout success component. This component is responsible for summarizing the payment information.
 *
 * @returns {JSX.Element}
 *
 * @constructor
 */
const CheckoutSuccess: Component = () => {
  const [loading, setLoading] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState();

  const { id } = useParams();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const secureId = searchParams.get('secure_id');

  /**
   * Sets the paymentInfo variable based on the current paymentIntent.
   *
   * @type {unknown}
   */
  const paymentInfo = useMemo(() => paymentIntent && _.first(paymentIntent.charges.data), [paymentIntent]);

  /**
   * Sets the quantity of license keys purchased.
   *
   * @type {number}
   */
  const quantity = useMemo(() => {
    let value = 1;

    if (paymentIntent && paymentIntent.metadata) {
      value = Number.parseInt(paymentIntent.metadata.quantity, 10);
    }

    return value;
  }, [paymentIntent]);

  /**
   * Loads the payment intent when the component mounts.
   */
  useEffect(() => {
    setLoading(true);

    PaymentIntents
      .fetchOne(id)
      .then(({ data }) => setPaymentIntent(data.payment_intent))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <section
        className='pt-6 pt-md-8 pb-8 mb-md-8'
      >
        <div
          className='container'
        >
          <div
            className='row'
          >
            <div
              className='col-12'
            >
              <div
                className='row justify-content-center mb-6 mb-md-8 text-center'
              >
                <div
                  className='col-12 col-md-8'
                >
                  <div
                    className='icon-circle bg-primary text-white mb-6'
                  >
                    <i
                      className='fe fe-check'
                    />
                  </div>
                  <h2
                    className='fw-bold mb-0'
                  >
                    { paymentIntent && paymentIntent.renewal
                      ? t('CheckoutSuccess.header.renewal')
                      : t('CheckoutSuccess.header.initial') }
                  </h2>
                  { paymentIntent && (
                    <p
                      className='fs-lg text-gray-700 mb-0'
                    >
                      { paymentIntent.renewal
                        ? t('CheckoutSuccess.subheader.renewal')
                        : t('CheckoutSuccess.subheader.initial', { email: paymentIntent.customer.email })}
                    </p>
                  )}
                </div>
              </div>
              { paymentIntent && !paymentIntent.renewal && (
                <div
                  className='row justify-content-center mb-6 mb-md-8'
                >
                  <div
                    className='col-12 col-md-6 col-lg-4'
                  >
                    { quantity === 1 && (
                      <CardLink
                        content={t('CheckoutSuccess.labels.activate')}
                        header={t('CheckoutSuccess.labels.download')}
                        icon='fe-download-cloud'
                        url={`/download/${secureId}`}
                      />
                    )}
                    { quantity > 1 && (
                      <CardLink
                        content={t('CheckoutSuccess.labels.licenses')}
                        header={t('CheckoutSuccess.labels.manage')}
                        icon='fe-settings'
                        url={`/manage/${secureId}`}
                      />
                    )}
                  </div>
                </div>
              )}
              { loading && (
                <Loader />
              )}
              { paymentInfo && paymentIntent && (
                <ul
                  className='list-group shadow-light-lg mb-5 mb-md-6'
                >
                  <OrderRow
                    label={t('CheckoutSuccess.labels.name')}
                    value={paymentInfo.billing_details.name}
                  />
                  <OrderRow
                    label={t('CheckoutSuccess.labels.date')}
                    value={DateUtils.getDateTimeFromNumber(paymentIntent.created, FormatType.dateTime)}
                  />
                  <OrderRow
                    label={t('CheckoutSuccess.labels.type')}
                    value={paymentInfo.payment_method_details.card.brand}
                  />
                  <OrderRow
                    label={t('CheckoutSuccess.labels.method')}
                    value={`xxxx xxxx xxxx ${paymentInfo.payment_method_details.card.last4}`}
                  />
                  <OrderRow
                    label={t('CheckoutSuccess.labels.total')}
                    value={PriceUtils.formatTotal(paymentIntent.amount)}
                  />
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
      <div
        className='position-relative'
      >
        <div
          className='shape shape-bottom shape-fluid-x text-gray-200'
        >
          <Curve1 />
        </div>
      </div>
    </>
  );
};

export default CheckoutSuccess;
