// @flow

const INTEGER_REGEX = /^-?\d+$/;

type IsIntegerType = (value: any) => boolean;

/**
 * Returns true if the passed value is an integer.
 *
 * @param value
 *
 * @returns {boolean}
 */
const isInteger: IsIntegerType = (value: any) => INTEGER_REGEX.test(value);

export default {
  isInteger
};
