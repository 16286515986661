// @flow

import React, { useEffect, useMemo, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import _ from 'underscore';
import CopyButton from '../components/CopyButton';
import Customers from '../services/stripe/Customers';
import DateUtils, { FormatType } from '../utils/Date';
import Loader from '../components/Loader';
import ManageSubscription from '../components/ManageSubscription';
import RenewalTypes from '../constants/RenewalTypes';
import UserLicenseUtils from '../utils/UserLicense';
import UserLicenses from '../services/public/UserLicenses';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

/**
 * Renders the status of the user license.
 *
 * @constructor
 */
const StatusLabel = withTranslation()((props) => {
  const today = new Date();

  /**
   * Sets the expiration date Date object based on the expirationDate prop.
   *
   * @type {null}
   */
  const expirationDate = useMemo(() => {
    let date = null;

    if (props.expirationDate) {
      date = new Date(props.expirationDate);
    }

    return date;
  }, [props.expirationDate]);

  /**
   * Sets the className variable to set on the component.
   *
   * @type {string}
   */
  const className = useMemo(() => {
    const classNames = ['badge'];

    if (!expirationDate) {
      classNames.push('bg-info-soft');
    } else if (expirationDate > today) {
      classNames.push('bg-success-soft');
    } else if (expirationDate < today) {
      classNames.push('bg-danger-soft');
    }

    return classNames.join(' ');
  }, [expirationDate]);

  /**
   * Sets the label to display on the component.
   *
   * @type {*}
   */
  const label = useMemo(() => {
    let value;

    if (!expirationDate) {
      value = props.t('ManageLicenses.labels.inactive');
    } else if (expirationDate > today) {
      value = props.t('ManageLicenses.labels.active', {
        expirationDate: DateUtils.getDateTimeFromDate(expirationDate, FormatType.date)
      });
    } else if (expirationDate < today) {
      value = props.t('ManageLicenses.labels.expired', {
        expirationDate: DateUtils.getDateTimeFromDate(expirationDate, FormatType.date)
      });
    }

    return value;
  }, [expirationDate]);

  return (
    <span
      className={className}
    >
      { label }
    </span>
  );
});

/**
 * Renders the license management page. This page is responsible for displaying all of the license keys purchased within
 * a group and allow various actions.
 *
 * @type @constructor
 */
const ManageLicenses: Component = withTranslation()((props: Translateable) => {
  const [loading, setLoading] = useState(false);
  const [subscriptionUrl, setSubscriptionUrl] = useState();
  const [userLicenses, setUserLicenses] = useState();

  const { id } = useParams();

  /**
   * Sets the first user license in the group.
   */
  const firstLicense = useMemo(() => _.first(userLicenses), [userLicenses]);

  /**
   * Sets the value to true if the first license has a subscription ID and is not canceled.
   *
   * @type {boolean}
   */
  const subscription = useMemo(() => {
    let value = false;

    if (firstLicense && firstLicense.subscription_id && !firstLicense.subscription_canceled_at) {
      value = true;
    }

    return value;
  }, [firstLicense]);

  /**
   * Sets the renewal variable. This value is "true" if all of the licenses are being renewed as a group.
   *
   * @type {*}
   */
  const renewal = useMemo(() => {
    let value = false;

    if (firstLicense) {
      // If the licenses are setup to renew independently, do not display the renew button. If the licenses are setup
      // for automatic renewal, do not display the renew button.
      if (firstLicense.renewal_type !== RenewalTypes.independent && !subscription) {
        value = true;
      }
    }

    return value;
  }, [firstLicense, subscription]);

  /**
   * Loads all of the user licenses for the secure ID.
   */
  useEffect(() => {
    setLoading(true);

    UserLicenses
      .fetchAll({ secure_id: id })
      .then(({ data }) => setUserLicenses(data.user_licenses))
      .finally(() => setLoading(false));
  }, []);

  /**
   * Loads the subscription management URL (Stripe) for the current customer.
   */
  useEffect(() => {
    if (subscription && firstLicense) {
      Customers
        .session(firstLicense.user.stripe_id, { url: window.location.href })
        .then(({ data }) => setSubscriptionUrl(data.url));
    }
  }, [firstLicense, subscription]);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className='container'
    >
      <div
        className='row justify-content-center gx-0 min-vh-100 pb-12'
      >
        <div
          className='col-12'
        >
          <div
            className='d-flex align-items-center'
          >
            <div
              className='icon-circle bg-primary text-white'
            >
              <i
                className='fe fe-settings'
              />
            </div>
            <h2
              className='fw-bold mb-0 mx-4'
            >
              { props.t('ManageLicenses.header') }
            </h2>
          </div>
          { _.isEmpty(userLicenses) && (
            <p
              className='fs-lg text-gray-700 my-6'
            >
              <Trans
                i18nKey='ManageLicenses.messages.noRecords'
              >
                All of your licenses have been renewed by other users. If you believe this to be an error, please
                <Link
                  className='text-success'
                  to='/contact'
                >
                  contact us
                </Link>
                and we'll get back to you as quickly as possible.
              </Trans>
            </p>
          )}
          { !_.isEmpty(userLicenses) && (
            <>
              <p
                className='text-muted my-6'
              >
                { props.t('ManageLicenses.messages.instructions') }
              </p>
              <p
                className='text-muted my-6'
              >
                { props.t('ManageLicenses.messages.bookmark') }
              </p>
              <ul
                className='list-group list-unstyled shadow-light-lg mb-5 mb-md-6 p-0 my-3'
              >
                { _.map(userLicenses, (userLicense) => (
                  <li
                    className='list-group-item justify-content-between'
                  >
                    <h5
                      className='text-capitalize'
                    >
                      { userLicense.license_key }
                    </h5>
                    <StatusLabel
                      expirationDate={userLicense.expires_at}
                    />
                    <div
                      className='float-end'
                    >
                      <CopyButton
                        className='btn btn-secondary-soft shadow lift btn-rounded-circle mx-2'
                        text={userLicense.license_key}
                      />
                      <a
                        className='btn btn-secondary-soft shadow lift btn-rounded-circle mx-2'
                        href={UserLicenseUtils.getDownloadUrl(userLicense)}
                        rel='noreferrer'
                        target='_blank'
                        type='button'
                      >
                        <i
                          className='fe fe-lg fe-download-cloud'
                        />
                      </a>
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
          <div
            className='d-flex justify-content-end'
          >
            { renewal && (
              <Link
                className='btn btn-lg btn-primary shadow lift mx-2'
                to={`/renew/${id}?renewal_type=${RenewalTypes.group}`}
              >
                { props.t('ManageLicenses.buttons.renew')}
              </Link>
            )}
          </div>
          { subscriptionUrl && (
            <div
              className='d-flex flex-column align-items-center justify-content-center py-12'
            >
              <ManageSubscription
                className='col-12 col-md-8 text-center'
                url={subscriptionUrl}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default ManageLicenses;
