// @flow

import BaseConstants from './BaseConstants';
import LicenseTypesData from '../resources/LicenseTypes.json';

class LicenseTypes extends BaseConstants {
  free: string;
  paid: string;
}

const LicenseTypesConstants: LicenseTypes = new LicenseTypes(LicenseTypesData);
export default LicenseTypesConstants;
