// @flow

import ApplicationSetting from '../transforms/ApplicationSetting';
import BaseService from './BaseService';

/**
 * Class for handling all application settings API calls.
 */
class ApplicationSettings extends BaseService {
  /**
   * Returns the application settings base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/application_settings';
  }

  /**
   * Returns the application settings transform.
   *
   * @returns {ApplicationSetting}
   */
  getTransform(): * {
    return ApplicationSetting;
  }
}

const ApplicationSettingsService: ApplicationSettings = new ApplicationSettings();
export default ApplicationSettingsService;
