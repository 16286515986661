// @flow

import React, { useEffect, useState } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Loader from './Loader';
import PriceCard from './PriceCard';
import PriceUtils from '../utils/Price';
import PricesService from '../services/stripe/Prices';
import { ReactComponent as Group } from '../icons/duotone-icons/communication/group.svg';
import { ReactComponent as User } from '../icons/duotone-icons/general/user.svg';
import RenewalTypes from '../constants/RenewalTypes';

import type { Component } from '../types/Component';

const PriceCards: Component = withTranslation()((props) => {
  const [initialPrice, setInitialPrice] = useState();
  const [renewalPrice, setRenewalPrice] = useState();

  /**
   * Loads the initial and renewal prices.
   */
  useEffect(() => {
    PricesService
      .fetchInitialPrice()
      .then(({ data }) => setInitialPrice(data.price));

    PricesService
      .fetchRenewalPrice()
      .then(({ data }) => setRenewalPrice(data.price));
  }, []);

  if (!(initialPrice && renewalPrice)) {
    return null;
  }

  return (
    <div
      className='container'
    >
      <div
        className='row justify-content-center mb-6'
      >
        <div
          className='col-12 col-md-10 col-lg-8 text-center'
        >
          <h1>
            <Trans
              i18nKey='PriceCards.header'
            >
              Start Your
              <span className='text-success'>
                FairCopy
              </span>
              Project Today!
            </Trans>
          </h1>
          <p
            className='lead text-gray-700 px-6'
          >
            { props.t('PriceCards.subheader') }
          </p>
        </div>
      </div>
      <div
        className='row align-items-center'
      >
        { !(initialPrice && renewalPrice) && (
          <Loader />
        )}
        { initialPrice && renewalPrice && (
          <>
            <PriceCard
              button={props.t('PriceCards.buttons.buy')}
              icon={<User />}
              items={[
                props.t('PriceCards.individual.initialPrice', {
                  price: PriceUtils.formatTotal(PriceUtils.calculateTotal(initialPrice, 1), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                  })
                }),
                props.t('PriceCards.individual.updates'),
                props.t('PriceCards.individual.renewalPrice', {
                  price: PriceUtils.formatTotal(PriceUtils.calculateTotal(renewalPrice, 1), {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                  })
                })
              ]}
              type={props.t('PriceCards.types.individual')}
              url={PriceUtils.getCheckoutUrl(initialPrice.id, 1, RenewalTypes.independent)}
            />
            <PriceCard
              button={props.t('PriceCards.buttons.buy')}
              icon={<Group />}
              items={[
                props.t('PriceCards.group.discount'),
                props.t('PriceCards.group.updates'),
                props.t('PriceCards.group.renew')
              ]}
              type={props.t('PriceCards.types.group')}
              url='/buy'
            />
          </>
        )}
      </div>
    </div>
  );
});

export default PriceCards;
