// @flow

import React, { useMemo, type Element, type Node } from 'react';
import { withTranslation } from 'react-i18next';

import type { Component } from '../types/Component';
import type { Translateable } from '../types/Translateable';

type Props = Translateable & {
  className?: string,
  closeable?: boolean,
  id: string,
  children: Node,
  scrollable?: boolean,
  title?: string | Element<any>,
};

const Modal: Component = withTranslation()((props: Props) => {
  /**
   * Sets the className variable for the current modal.
   *
   * @type {string}
   */
  const className = useMemo(() => {
    const classNames = ['modal-dialog', 'modal-dialog-centered'];

    if (props.className) {
      classNames.push(props.className);
    }

    if (props.scrollable) {
      classNames.push('modal-dialog-scrollable');
    }

    return classNames.join(' ');
  }, [props.className, props.scrollable]);

  return (
    <div
      className='modal fade'
      id={props.id}
      tabIndex='-1'
      role='dialog'
      aria-labelledby='modalExampleTitle'
      aria-hidden='true'
    >
      <div
        className={className}
        role='document'
      >
        <div
          className='modal-content'
        >
          <div
            className='modal-header'
          >
            { props.title && (
              <h2
                className='modal-title'
              >
                { props.title }
              </h2>
            )}
            <button
              aria-label='Close'
              className='btn-close close'
              data-bs-dismiss='modal'
              type='button'
            />
          </div>
          <div
            className='modal-body'
          >
            { props.children }
          </div>
          { props.closeable && (
            <div
              className='modal-footer'
            >
              <button
                type='button'
                className='btn btn-secondary'
                data-bs-dismiss='modal'
              >
                { props.t('Common.buttons.close') }
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

export default Modal;
