// @flow

import React from 'react';
import { FilterTypes, ListFilters, ListTable } from 'react-components';
import { withTranslation } from 'react-i18next';
import DateUtils, { FormatType } from '../../utils/Date';
import LicenseGroupModal from '../../components/LicenseGroupModal';
import LicenseGroupUtils from '../../utils/LicenseGroup';
import LicenseGroupsService from '../../services/LicenseGroups';

import type { Translateable } from '../../types/Translateable';
import type { Component } from '../../types/Component';

const LicenseGroups = (props: Translateable) => (
  <ListTable
    actions={[{
      name: 'edit'
    }, {
      name: 'delete'
    }, {
      accept: (lg) => lg.payment_intent_id,
      name: 'payment',
      icon: 'shopping cart',
      onClick: (lg) => window.open(LicenseGroupUtils.getPaymentUrl(lg, true))
    }, {
      accept: (lg) => lg.secure_id,
      name: 'manage',
      icon: 'cog',
      onClick: (lg) => window.open(LicenseGroupUtils.getManageUrl(lg))
    }]}
    addButton={{
      basic: false,
      color: 'green',
      location: 'top'
    }}
    className='license-groups'
    collectionName='license_groups'
    columns={[{
      name: 'users.name',
      label: props.t('LicenseGroups.columns.name'),
      resolve: (lg) => lg.user && lg.user.name,
      sortable: true,
      hidden: true
    }, {
      name: 'users.email',
      label: props.t('LicenseGroups.columns.email'),
      resolve: (lg) => lg.user && lg.user.email,
      sortable: true
    }, {
      name: 'license_count',
      label: props.t('LicenseGroups.columns.count'),
      sortable: true
    }, {
      name: 'name',
      label: props.t('LicenseGroups.columns.description'),
      sortable: true
    }, {
      name: 'created_at',
      label: props.t('LicenseGroups.columns.createdAt'),
      resolve: (lg) => DateUtils.getDateTimeFromString(lg.created_at, FormatType.date),
      sortable: true
    }, {
      name: 'secure_id',
      label: props.t('LicenseGroups.columns.secureId'),
      sortable: true,
      hidden: true
    }, {
      name: 'last_email_sent_at',
      label: props.t('LicenseGroups.columns.lastReminder'),
      resolve: (lg) => DateUtils.getDateTimeFromString(lg.last_email_sent_at, FormatType.date),
      sortable: true,
      hidden: false
    }]}
    defaultSort='created_at'
    defaultSortDirection='descending'
    filters={{
      component: ListFilters,
      props: {
        filters: [{
          attributeName: 'users.email',
          key: 'email',
          label: props.t('LicenseGroups.filters.email'),
          type: FilterTypes.string
        }, {
          attributeName: 'license_count',
          key: 'license_count',
          label: props.t('LicenseGroups.filters.licenseCount'),
          type: FilterTypes.integer
        }, {
          attributeName: 'description',
          key: 'description',
          label: props.t('LicenseGroups.filters,description'),
          type: FilterTypes.text
        }, {
          attributeName: 'created_at',
          key: 'created_at',
          label: props.t('LicenseGroups.filters.createdAt'),
          type: FilterTypes.date
        }, {
          attributeName: 'last_email_sent_at',
          key: 'last_email_sent_at',
          label: props.t('LicenseGroups.filters.lastReminder'),
          type: FilterTypes.date
        }, {
          attributeName: 'secure_id',
          key: 'secure_id',
          label: props.t('LicenseGroups.filters.secureId'),
          type: FilterTypes.string
        }]
      }
    }}
    modal={{
      component: LicenseGroupModal,
      props: {
        defaults: {
          license_count: 2
        },
        onInitialize: (id) => LicenseGroupsService.fetchOne(id).then(({ data }) => data.license_group)
      }
    }}
    onDelete={(lg) => LicenseGroupsService.delete(lg)}
    onLoad={(params) => LicenseGroupsService.search(params)}
    onSave={(lg) => LicenseGroupsService.save(lg)}
    perPageOptions={[10, 25, 50, 100]}
    session={{
      key: 'license_groups'
    }}
  />
);

const LicenseGroupsPage: Component = withTranslation()(LicenseGroups);
export default LicenseGroupsPage;
