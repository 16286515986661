// @flow

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'underscore';
import Customers from '../services/stripe/Customers';
import Errors from '../components/Errors';
import Loader from '../components/Loader';
import ManageSubscription from '../components/ManageSubscription';
import UserLicenses from '../services/public/UserLicenses';

import type { Component } from '../types/Component';

const Subscription: Component = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState();
  const [url, setUrl] = useState();

  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();

  /**
   * Sets the error messages on the state.
   *
   * @type {function({response: {data: *}}): void}
   */
  const onError = useCallback(({ response: { data } }) => setErrors(data.errors), []);

  /**
   * Calls the /api/stripe/customers/:id/session API end point.
   *
   * @type {function(*): Q.Promise<void>}
   */
  const onLoadSession = useCallback((userLicense) => (
    Customers
      .session(userLicense.user.stripe_id, { url: window.location.href })
      .then(({ data }) => {
        // If the subscription has been canceled, redirect to the home page.
        if (data.url) {
          setUrl(data.url);
        } else {
          navigate('/');
        }
      })
      .catch(onError)
  ), []);

  /**
   * Load the user licenses for the passed payment_intent_id when the component loads.
   */
  useEffect(() => {
    setLoading(true);

    UserLicenses
      .fetchAll({ secure_id: id })
      .then(({ data }) => onLoadSession(_.first(data.user_licenses)))
      .catch(onError)
      .finally(() => setLoading(false));
  }, []);

  return (
    <section
      className='pt-9 pt-md-8 pb-12'
    >
      <div
        className='container'
      >
        <div
          className='row justify-content-center gx-0 min-vh-100'
        >
          { loading && (
            <Loader />
          )}
          { errors && (
            <Errors
              className='col-12 col-md-8 mb-auto mb-md-0 pb-8 py-6 py-md-11'
              errors={errors}
              message={t('Subscription.errors')}
            />
          )}
          { url && (
            <ManageSubscription
              className='col-12 col-md-10 col-lg-8 text-center'
              url={url}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default Subscription;
