// @flow

import React, { useCallback, useState } from 'react';
import {
  BooleanIcon,
  DropdownButton,
  FilterTypes,
  ListFilters,
  ListTable,
  Toaster
} from 'react-components';
import { withTranslation } from 'react-i18next';
import { Confirm, Message } from 'semantic-ui-react';
import _ from 'underscore';
import DateUtils, { FormatType } from '../../utils/Date';
import LicenseTypes from '../../constants/LicenseTypes';
import RenewalTypes from '../../constants/RenewalTypes';
import UserLicenseModal from '../../components/UserLicenseModal';
import UserLicenseUtils from '../../utils/UserLicense';
import UserLicensesService from '../../services/UserLicenses';

import type { Component } from '../../types/Component';
import type { Translateable } from '../../types/Translateable';

const Reminders = {
  renewal: {
    id: 'renewal',
    onClick: () => UserLicensesService.sendRenewalReminders()
  },
  subscription: {
    id: 'subscription',
    onClick: () => UserLicensesService.sendSubscriptionReminders()
  }
};

const UserLicenses = (props: Translateable) => {
  const [reminder, setReminder] = useState();
  const [toaster, setToaster] = useState(false);

  /**
   * Renders the reminders dropdown button component.
   *
   * @type {unknown}
   */
  const renderRemindersButton = useCallback(() => (
    <DropdownButton
      color='blue'
      icon='bell outline'
      onChange={(e, { value }) => setReminder(value)}
      options={[{
        key: Reminders.renewal,
        value: Reminders.renewal,
        text: props.t('UserLicenses.labels.renewalReminders')
      }, {
        key: Reminders.subscription,
        value: Reminders.subscription,
        text: props.t('UserLicenses.labels.subscriptionReminders')
      }]}
      text={props.t('UserLicenses.buttons.reminders')}
      value=''
    />
  ), []);

  return (
    <>
      <ListTable
        actions={[{
          name: 'edit'
        }, {
          name: 'delete'
        }, {
          accept: (ul) => ul.secure_id,
          name: 'download',
          icon: 'cloud download',
          onClick: (ul) => window.open(UserLicenseUtils.getDownloadUrl(ul, true))
        }, {
          accept: (ul) => ul.payment_intent_id,
          name: 'payment',
          icon: 'shopping cart',
          onClick: (ul) => window.open(UserLicenseUtils.getPaymentUrl(ul, true))
        }, {
          accept: (ul) => (
            ul.secure_id && ul.subscription_id && ul.subscription_id.length && !ul.subscription_canceled_at
          ),
          name: 'subscription',
          icon: 'calendar check outline',
          onClick: (ul) => window.open(UserLicenseUtils.getSubscriptionUrl(ul, true))
        }]}
        addButton={{
          basic: false,
          color: 'green',
          location: 'top'
        }}
        buttons={[{
          render: renderRemindersButton
        }]}
        className='user-licenses'
        collectionName='user_licenses'
        columns={[{
          name: 'users.name',
          label: props.t('UserLicenses.columns.name'),
          resolve: (ul) => ul.user && ul.user.name,
          sortable: true,
          hidden: true
        }, {
          name: 'users.email',
          label: props.t('UserLicenses.columns.email'),
          resolve: (ul) => ul.user && ul.user.email,
          sortable: true
        }, {
          name: 'license_key',
          label: props.t('UserLicenses.columns.licenseKey'),
          sortable: true
        }, {
          name: 'license_type',
          label: props.t('UserLicenses.columns.licenseType'),
          resolve: (ul) => LicenseTypes.getText(ul.license_type),
          sortable: true
        }, {
          name: 'renewal_type',
          label: props.t('UserLicenses.columns.renewalType'),
          resolve: (ul) => RenewalTypes.getText(ul.renewal_type),
          sortable: true
        }, {
          name: 'created_at',
          label: props.t('UserLicenses.columns.createdAt'),
          resolve: (ul) => DateUtils.getDateTimeFromString(ul.created_at, FormatType.date),
          sortable: true
        }, {
          name: 'expires_at',
          label: props.t('UserLicenses.columns.expiresAt'),
          resolve: (ul) => DateUtils.getDateTimeFromString(ul.expires_at, FormatType.date),
          sortable: true
        }, {
          name: 'group_license',
          label: props.t('UserLicenses.columns.group'),
          render: (ul) => <BooleanIcon value={!!ul.license_group_id} />
        }, {
          name: 'secure_id',
          label: props.t('UserLicenses.columns.secureId'),
          sortable: true,
          hidden: true
        }, {
          name: 'payment_intent_id',
          label: props.t('UserLicenses.columns.paymentIntentId'),
          sortable: true,
          hidden: true
        }, {
          name: 'last_email_sent_at',
          label: props.t('UserLicenses.columns.lastReminder'),
          resolve: (ul) => DateUtils.getDateTimeFromString(ul.last_email_sent_at, FormatType.date),
          sortable: true,
          hidden: true
        }, {
          name: 'activation_token',
          label: props.t('UserLicenses.columns.activationToken'),
          sortable: true,
          hidden: true
        }, {
          name: 'subscription_id',
          label: props.t('UserLicenses.columns.subscription'),
          render: (ul) => <BooleanIcon value={!!ul.subscription_id} />,
          sortable: true,
          hidden: true
        }, {
          name: 'subscription_canceled_at',
          label: props.t('UserLicenses.columns.subscriptionCanceledAt'),
          resolve: (ul) => DateUtils.getDateTimeFromString(ul.subscription_canceled_at, FormatType.dateTime),
          sortable: true,
          hidden: true
        }, {
          name: 'machine_uuid',
          label: props.t('UserLicenses.columns.machineUuid'),
          sortable: true,
          hidden: true
        }]}
        defaultSort='created_at'
        defaultSortDirection='descending'
        filters={{
          component: ListFilters,
          props: {
            filters: [{
              attributeName: 'users.email',
              key: 'email',
              label: props.t('UserLicenses.filters.email'),
              type: FilterTypes.string
            }, {
              attributeName: 'license_key',
              key: 'license_key',
              label: props.t('UserLicenses.filters.licenseKey'),
              type: FilterTypes.string
            }, {
              attributeName: 'license_type',
              key: 'license_type',
              label: props.t('UserLicenses.filters.licenseType'),
              options: _.map(LicenseTypes.getValues(), (lt) => ({
                key: lt.id,
                value: lt.id,
                text: lt.shortText
              })),
              type: FilterTypes.select
            }, {
              attributeName: 'renewal_type',
              key: 'renewal_type',
              label: props.t('UserLicenses.filters.renewalType'),
              options: _.map(RenewalTypes.getValues(), (rt) => ({
                key: rt.id,
                value: rt.id,
                text: rt.shortText
              })),
              type: FilterTypes.select
            }, {
              attributeName: 'created_at',
              key: 'created_at',
              label: props.t('UserLicenses.filters.createdAt'),
              type: FilterTypes.date
            }, {
              attributeName: 'expires_at',
              key: 'expires_at',
              label: props.t('UserLicenses.filters.expiresAt'),
              type: FilterTypes.date
            }, {
              attributeName: 'last_email_sent_at',
              key: 'last_email_sent_at',
              label: props.t('UserLicenses.filters.lastReminder'),
              type: FilterTypes.date
            }, {
              attributeName: 'activation_token',
              key: 'activation_token',
              label: props.t('UserLicenses.filters.activationToken'),
              type: FilterTypes.string
            }, {
              attributeName: 'license_group_id',
              key: 'license_group_id',
              label: props.t('UserLicenses.filters.licenseGroupId'),
              type: FilterTypes.integer
            }, {
              attributeName: 'subscription_id',
              key: 'subscription_id',
              label: props.t('UserLicenses.filters.subscriptionId'),
              type: FilterTypes.text
            }, {
              attributeName: 'subscription_canceled_at',
              key: 'subscription_canceled_at',
              label: props.t('UserLicenses.filters.subscriptionCanceledAt'),
              type: FilterTypes.date
            }]
          }
        }}
        modal={{
          component: UserLicenseModal,
          props: {
            onInitialize: (id) => (
              UserLicensesService
                .fetchOne(id)
                .then(({ data }) => data.user_license)
            ),
            resolveValidationError: ({ key, error }) => ({ [key]: error }),
            validate: (item) => {
              const errors = {};

              if (item.activate && !item.machine_uuid) {
                errors.machine_uuid = props.t('UserLicenses.errors.machine_uuid');
              }

              return errors;
            }
          }
        }}
        onDelete={(userLicense) => UserLicensesService.delete(userLicense)}
        onLoad={(params) => UserLicensesService.search(params)}
        onSave={(userLicense) => UserLicensesService.save(userLicense)}
        perPageOptions={[10, 25, 50, 100]}
        session={{
          key: 'user_licenses'
        }}
      />
      { reminder && (
        <Confirm
          content={props.t(`UserLicenses.messages.${reminder.id}.content`)}
          header={props.t(`UserLicenses.messages.${reminder.id}.header`)}
          onCancel={() => setReminder(null)}
          onConfirm={() => {
            setReminder(null);

            reminder
              .onClick()
              .then(() => setToaster(true));
          }}
          open
        />
      )}
      { toaster && (
        <Toaster
          onDismiss={() => setToaster(false)}
          type={Toaster.MessageTypes.positive}
        >
          <Message.Header
            content={props.t('UserLicenses.messages.reminders.header')}
          />
          <Message.Content
            content={props.t('UserLicenses.messages.reminders.content')}
          />
        </Toaster>
      )}
    </>
  );
};

const UserLicensesPage: Component = withTranslation()(UserLicenses);
export default UserLicensesPage;
