// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import type { Component } from '../types/Component';

const Testimonials: Component = withTranslation()((props) => (
  <section
    className={props.className}
  >
    <div
      className='container pt-8 pt-md-12'
    >
      <div
        className='row justify-content-center'
      >
        <div
          className='col-12 col-md-10 col-lg-8 text-center text-white'
        >
          <h1>
            { props.t('Testimonials.header') }
          </h1>
        </div>
      </div>
      <div
        className='row'
      >
        <div
          className='col-12'
        >
          <div
            className='card card-row shadow-light-lg mb-6'
          >
            <div
              className='row gx-0 justify-content-center'
            >
              <div
                className='col-12 col-md-6 position-md-static'
              >
                <div
                  className='position-md-static'
                  data-flickity={JSON.stringify({
                    wrapAround: true,
                    pageDots: false,
                    adaptiveHeight: true
                  })}
                  id='blogSlider'
                >
                  <div
                    className='w-100'
                  >
                    <div
                      className='card-body'
                    >
                      <blockquote
                        className='blockquote text-center mb-0'
                      >
                        <p
                          className='mb-5 mb-md-7'
                        >
                          { props.t('Testimonials.user1.content') }
                        </p>
                        <footer
                          className='blockquote-footer'
                        >
                            <span
                              className='h6 text-uppercase'
                            >
                              { props.t('Testimonials.user1.name') }
                            </span>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                  <div
                    className='w-100'
                  >
                    <div
                      className='card-body'
                    >
                      <blockquote
                        className='blockquote text-center mb-0'
                      >
                        <p
                          className='mb-5 mb-md-7'
                        >
                          { props.t('Testimonials.user2.content') }
                        </p>
                        <footer
                          className='blockquote-footer'
                        >
                            <span
                              className='h6 text-uppercase'
                            >
                              { props.t('Testimonials.user2.name') }
                            </span>
                        </footer>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className='row'
      >
        <div
          className='col-12 pt-8 pt-md-6 text-center'
        >
          <Link
            className='btn btn-success btn-lg lift'
            to='/sign_up'
          >
            { props.t('Home.buttons.trial') }
            <i className='fe fe-arrow-right d-none d-md-inline ms-3' />
          </Link>
        </div>
      </div>
    </div>
  </section>
));

export default Testimonials;
