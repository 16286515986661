// @flow

import React, { type Element } from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import type { Component } from '../types/Component';

type Props = {
  button: string,
  icon: Element<any>,
  items: Array<string>,
  type: string,
  url: string
};

const PriceCard: Component = (props: Props) => (
  <div
    className='col-12 col-md-6'
  >
    <div
      className='card rounded-lg shadow-lg mb-6 mb-md-0'
      data-aos='fade-up'
      style={{
        zIndex: '1'
      }}
    >
      <div
        className='card-body py-6 py-md-8'
      >
        <div
          className='row justify-content-center'
        >
          <div
            className='col-12 col-xl-9'
          >
            <div
              className='text-center mb-5'
            >
              <span
                className='badge rounded-pill bg-primary-soft'
              >
                <span
                  className='h6 fw-bold text-uppercase'
                >
                  { props.type }
                </span>
              </span>
            </div>
            <div
              className='d-flex justify-content-center mb-4'
            >
              <div
                className='icon icon-xl'
              >
                { props.icon }
              </div>
            </div>
            { _.map(props.items, (item) => (
              <div
                className='d-flex'
              >
                <div
                  className='badge badge-rounded-circle bg-success-soft mt-1 me-4'
                >
                  <i
                    className='fe fe-check'
                  />
                </div>
                <p>
                  { item }
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Link
        to={props.url}
        className='card-btn btn w-100 btn-lg btn-primary'
      >
        { props.button }
      </Link>
    </div>
  </div>
);

export default PriceCard;
