// @flow

import _ from 'underscore';
import BaseService from '../BaseService';

class Users extends BaseService {
  /**
   * Returns the public users API base URL.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/public/users';
  }

  /**
   * Returns the users transform.
   *
   * @returns {{toPayload: (function(*): *)}}
   */
  getTransform(): * {
    return {
      toPayload: (item) => ({
        user: _.pick(item, 'subscribe')
      })
    };
  }
}

const UsersService: Users = new Users();
export default UsersService;
