// @flow

import BaseTransform from './BaseTransform';

/**
 * Class for handling transforming customer objects.
 */
class Customer extends BaseTransform {
  /**
   * Returns the customer payload keys.
   *
   * @returns {string[]}
   */
  getPayloadKeys(): Array<string> {
    return [
      'email',
      'name'
    ];
  }

  /**
   * Returns the customer parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    return 'customer';
  }
}

export default Customer;
