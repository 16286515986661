// @flow

import _ from 'underscore';

/**
 * Transforms base class.
 */
class BaseTransform {
  /**
   * Returns the hash of child attributes.
   *
   * @returns {{}}
   */
  getChildAttributes(): { [string]: Array<string> } {
    // Implemented in sub-classes.
    return {};
  }

  /**
   * Returns the parameter name.
   *
   * @returns {string}
   */
  getParameterName(): string {
    // Implemented in sub-classes.
    return '';
  }

  /**
   * Returns the list of payload keys.
   *
   * @returns {*[]}
   */
  getPayloadKeys(): Array<string> {
    // Implemented in sub-classes.
    return [];
  }

  /**
   * Converts the passed object to JSON to be sent on POST/PUT requests.
   *
   * @param item
   *
   * @returns any
   */
  toPayload(item: any): any {
    return {
      [this.getParameterName()]: {
        ..._.pick(item, this.getPayloadKeys()),
        ..._.mapObject(this.getChildAttributes(),
          (attributes, key) => _.map(item[key],
            (child) => _.pick(child, attributes)))
      }
    };
  }
}

export default BaseTransform;
