// @flow

import axios from 'axios';
import BaseService from '../BaseService';
import Customer from '../../transforms/Customer';

/**
 * Service class for handling customer requests.
 */
class Customers extends BaseService {
  /**
   * Returns the base URL for the customers API.
   *
   * @returns {string}
   */
  getBaseUrl(): string {
    return '/api/stripe/customers';
  }

  /**
   * Returns the customer transform object.
   *
   * @returns {Customer}
   */
  getTransform(): * {
    return new Customer();
  }

  /**
   * Returns the Stripe portal session URL for the passed customer ID.
   *
   * @param id
   * @param params
   *
   * @returns {Promise<any>}
   */
  session(id: string, params: any = {}): Promise<any> {
    return axios.get(`${this.getBaseUrl()}/${id}/session`, { params });
  }
}

const CustomersService: Customers = new Customers();
export default CustomersService;
