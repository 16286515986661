// @flow

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import _ from 'underscore';

import type { Component } from '../types/Component';

type Props = {
  className?: string,
  errors: Array<string>,
  message: string
};

const Errors: Component = (props: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div
      className={props.className}
    >
      <h1
        className='display-3 fw-bold text-center'
      >
        { t('Errors.header') }
      </h1>
      <p
        className='mb-5 text-center text-muted'
      >
        { props.message }
      </p>
      <ul
        className='list-group shadow mb-5 mb-md-6'
      >
        { _.map(props.errors, (error) => (
          <li
            className='list-group-item'
          >
            { error}
          </li>
        ))}
      </ul>
      <div
        className='text-center'
      >
        <button
          className='btn btn-primary'
          onClick={() => navigate(-1)}
          type='button'
        >
          <i className='fe fe-arrow-left-circle mx-2' />
          { t('Errors.buttons.back') }
        </button>
      </div>
    </div>
  );
};

export default Errors;
